import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newCampaignActions } from '../../../../store';

const CampaignReady = ({ canLaunch, dispatch, hasSchedules, userCpmMode }) => {
    const { t } = useTranslation();

    const handleLaunchCampaign = () => {
        dispatch(newCampaignActions.setCurrentWizard('launch'));
    };

    return (
        <div
            className={`${
                canLaunch && (hasSchedules || userCpmMode === 0) ? '' : 'hidden'
            } block py-4`}>
            <FontAwesomeIcon
                icon={['fad', 'grin-wink']}
                fixedWidth
                size="2x"
                className="mr-1 text-yellow-300"
            />{' '}
            {
                <span className="font-light text-xl">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.review.info'
                    )}
                    !
                </span>
            }
            <div className="mt-2 md:w-9/12">
                <p className="text-lg font-light">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.review.campaign-ready-to-start'
                    )}{' '}
                    <button
                        onClick={handleLaunchCampaign}
                        className="link font-medium">
                        {t(
                            'sections.campaigns.new-campaign.wizard-review.review.launch-the-campaign-now'
                        )}
                    </button>
                </p>
                <p className="text-gray-700 leading-6 font-light">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.review.there-are-screens-that-need-to-approve'
                    )}
                </p>
                <p className="text-gray-500 leading-6 font-light mt-2">
                    {t(
                        'sections.campaigns.new-campaign.wizard-review.review.we-will-let-you-know-as-soon'
                    )}
                </p>
            </div>
        </div>
    );
};

export default CampaignReady;
