import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import WizardTab from '../../components/Campaign/WizardTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WizardBasics from '../../components/Campaign/WizardBasics/WizardBasics';
import WizardLocation from '../../components/Campaign/WizardLocation/WizardLocation';
import WizardScreens from '../../components/Campaign/WizardScreens/WizardScreens';
import WizardAudiences from '../../components/Campaign/WizardAudiences/WizardAudiences';
import WizardCreatives from '../../components/Campaign/WizardCreatives/WizardCreatives';
import WizardReview from '../../components/Campaign/WizardReview/WizardReview';
import WizardLaunch from '../../components/Campaign/WizardLaunch/WizardLaunch';
import WizardBriefBar from '../../components/Campaign/WizardBriefBar/WizardBriefBar';
import MapCanvas from '../../components/Maps/MapCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { useService } from '../UseService';
import Loading from '../../components/Loading/Loading';
import { newCampaignActions, screensActions } from '../../store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    campaignStatus,
    getCpmSumFromSelectedScreens,
    injectCpmOnScreens,
} from '../../utils/functions';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader/ModalHeader';
import ModalBody from '../../components/Modal/ModalBody/ModalBody';
import CommonButton from '../../components/Buttons/CommonButton';
import ModalFooter from '../../components/Modal/ModalFooter/ModalFooter';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../containers/UseAuth';
import { getTierLists } from '../../services/pricingService';

function CreateCampaignPage() {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const newCampaign = useSelector((state) => state.newCampaign);
    const { screens, filteredScreens } = useSelector((state) => state.screens);
    const name = newCampaign.name;
    const brand = newCampaign.brand;
    const creatives = newCampaign.creatives;
    const sizesNeeded = newCampaign.sizesNeeded;
    const campaignId = newCampaign.id;
    const baseName = newCampaign.baseName;
    const selectedCreativeCategories = newCampaign.selectedCreativeCategories;
    const tracking = newCampaign.tracking;
    const selectedCurrency = newCampaign.selectedCurrency;
    const budget = newCampaign.budget;
    const dailyCap = newCampaign.dailyCap;
    const timing = newCampaign.timing;
    const totalSpent = newCampaign.totalSpent;
    const location = newCampaign.location;
    const mapLocations = newCampaign.mapLocations;
    const currencyDefault = JSON.parse(localStorage.getItem('currencyDefault'));
    const canLaunchCampaign = newCampaign.canLaunch;
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const currentWizard = newCampaign.currentWizard;
    const zones = newCampaign.location.zones;
    const stepper = newCampaign.stepper;
    const wallet = useSelector((state) => state.wallet);
    const currencies = wallet.currencies;
    const budgets = wallet.budgets;
    const service = useService();
    const [calculatingForecast, setCalculatingForecast] = useState(false);
    const [timer, setTimer] = useState(true);
    const [filterScreenByPoi, setFilterScreenByPoi] = useState(true);
    const [screenOpened, setScreenOpened] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);
    const [isLoadedData, setIsLoadedData] = useState(false);
    const [fetchingWithCpm, setFetchingWithCpm] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [loadingDataTable, setLoadingDataTable] = useState(false);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const [warningAccepted, setWarningAccepted] = useState(false);
    const [resetMap, setResetMap] = useState(true);
    const resizeMapTransitionDuration = 300;
    const toggleModalWarning = () => setShowModalWarning(!showModalWarning);
    const [campaignNameWarning, setCampaignNameWarning] = useState('');
    const [isFirstTime, setIsFirstTime] = useState(true);
    const selectedScreens = newCampaign.selectedScreens;
    const idCampaignRedux = newCampaign.id;
    const isSame = idCampaignRedux === parseInt(id);
    const [mapedScreens, setMapedScreens] = useState([]);
    // const [selectedScreensLocal, setSelectedScreensLocal] = useState(isSame ? selectedScreens : []);
    const { selectedScreensLocal, tierList } = useSelector(
        (state) => state.screens
    );
    const [hasSchedules, setHasSchedules] = useState(null);

    useEffect(() => {
        if (
            !newCampaign.isLaunching &&
            newCampaign.stepper.wizard !== 'launch' &&
            newCampaign.currentWizard !== 'launch'
        ) {
            setHasSchedules(null);
        }
    }, [newCampaign]);

    //it brings the tiers and make a key value object with id-screenType
    const fetchTierListAsIdName = async () => {
        if (!Object.keys(tierList).length) {
            let tiersFromApi = await getTierLists();
            let tiersByNames = {};

            let tiersByIds = {};
            tiersFromApi.message.forEach((tier) => {
                tiersByNames[tier.screenType] = tiersByNames[tier.screenType]
                    ? [...tiersByNames[tier.screenType], tier.id]
                    : [tier.id];
                tiersByIds[tier.id] = tier.screenType;
            });
            dispatch(
                screensActions.setTiers(
                    Object.keys(tiersByNames).map((keyName) => ({
                        screenType: keyName,
                        ids: tiersByNames[keyName],
                    }))
                )
            );
            dispatch(screensActions.setTierList(tiersByIds));
            return;
        } else {
            return;
        }
    };
    useEffect(() => {
        fetchTierListAsIdName();
    }, []);

    const setSelectedScreensLocal = (data) => {
        dispatch(screensActions.setSelectedScreensLocal(data));
    };
    const hasDiferrentScreens =
        selectedScreensLocal.length === 0 && selectedScreens.length > 0;
    //seting selectedLocalscreens when opening campaign
    useEffect(() => {
        if (isFirstTime && hasDiferrentScreens) {
            if (id && !isSame) return;
            else {
                setSelectedScreensLocal(selectedScreens);
                setIsFirstTime(false);
            }
        }
    }, [newCampaign.selectedScreens]);

    const [filteredScreensLocal, setFilteredScreensLocal] = useState([]);
    useEffect(() => {
        if (screenOpened) {
            let isInList = filteredScreensLocal.find(
                (screen) => screen.id == screenOpened.id
            );
            if (!isInList) {
                setScreenOpened(null);
            }
        }
    }, [filteredScreensLocal]);
    const [filteredScreensByLocation, setFilteredScreensByLocation] = useState(
        []
    );
    const [screensWithCpm, setScreensWithCpm] = useState([]);
    const campaignTimezone = newCampaign.timing.selectedTimezone.gmt || 0;
    const auth = useAuth();
    const userId = auth.session.user.id;
    const userMargin = auth.session.user.rtb_margin;
    const selectedCurrencyName = selectedCurrency?.short_name;
    const provider = newCampaign.demographics.id
        ? newCampaign.demographics?.id?.toLowerCase()
        : 'onemata';

    useEffect(() => {
        const fetchData = async () => {
            setFetchingWithCpm(true);

            service
                .getScreensReportsWithCpm(
                    provider,
                    userId,
                    selectedCurrencyName
                )
                .then((screensWithCpm) => {
                    setScreensWithCpm(screensWithCpm.data.message);
                })
                .catch((error) => {
                    console.error('getScreensReportsWithCpm', error);
                })
                .finally(() => {
                    setFetchingWithCpm(false);
                });
        };

        if (selectedCurrencyName) {
            fetchData();
        }
    }, [provider, campaignTimezone, selectedCurrencyName, userId]);

    useEffect(() => {
        if (
            !fetchingWithCpm &&
            screens?.length &&
            !fetchingData &&
            isLoadedData
        ) {
            if (auth.session.user.cpm_mode == 1 && !screensWithCpm.length) {
                return;
            }
            setLoadingDataTable(true);
            setTimeout(() => {
                let injectedScreens = injectCpmOnScreens(
                    screens,
                    screensWithCpm
                );
                if (injectedScreens?.length) {
                    setMapedScreens(injectedScreens);
                } else if (auth.session.user.cpm_mode == 0) {
                    setMapedScreens(screens);
                    setLoadingDataTable(false);
                }
            }, 0);
        }
    }, [fetchingWithCpm, screens]);

    useEffect(() => {
        if (auth.session?.user?.cpm_mode === 1) {
            const newBudget = Object.assign({}, budget);
            let maxCpm = getCpmSumFromSelectedScreens(
                selectedScreensLocal,
                userMargin
            );
            newBudget.cpm = maxCpm;
            dispatch(newCampaignActions.setBudget(newBudget));
        }
    }, [selectedScreensLocal, auth]);

    const defaultProps = {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 1,
    };

    const allWizards = [
        'basics',
        'location',
        'screens',
        'audiences',
        'creatives',
        'review',
        'launch',
    ];

    const editCampaign = async () => {
        const campaign = await service.getCampaignPreview(id);
        await service.editCampaign(campaign);
        service.continueNewCampaign();
        setIsLoadedData(true);
    };

    const onCancelWarning = () => history.push('/');
    const onAcceptWarning = () => setWarningAccepted(true);

    useEffect(() => {
        if (warningAccepted) {
            editCampaign();
            toggleModalWarning();
        }
    }, [warningAccepted]);

    // if can not launch the campaign, remove tab launch
    const wizards = canLaunchCampaign
        ? allWizards
        : allWizards.filter((w) => w !== 'launch');

    const handleWizard = (option) => {
        changeCurrentWizard(option);
    };

    const changeCurrentWizard = (option) => {
        let valueInsideRange = option;

        if (option === 6) {
            valueInsideRange = 5;
        } else if (option === -1) {
            valueInsideRange = 0;
        }

        let index =
            typeof option === 'number'
                ? valueInsideRange
                : wizards.indexOf(option);

        let wizard =
            typeof option === 'string' ? option : wizards[valueInsideRange];

        setPageNumber(index);

        dispatch(newCampaignActions.setStepper({ index, wizard }));
        // Keep in sync tabs
        dispatch(newCampaignActions.setCurrentWizard(wizard));
    };

    useEffect(() => {
        changeCurrentWizard(currentWizard);
    }, [currentWizard]);

    useEffect(() => {
        if (!isLoadedData) {
            if (id) {
                const fetchData = async () => {
                    if (currencies.length > 0 && !fetchingData) {
                        setFetchingData(true);
                        const campaign = await service.getCampaignPreview(id);
                        const isInDraft =
                            campaignStatus('DRAFT') === campaign?.status;
                        const isInInactive =
                            campaignStatus('INACTIVE') === campaign?.status;
                        if (isInDraft || isInInactive) {
                            editCampaign();
                        } else {
                            setShowModalWarning(true);
                            setCampaignNameWarning(campaign?.name);
                        }
                        setFetchingData(false);
                    }
                };
                fetchData();
            } else {
                service.continueNewCampaign();
                setIsLoadedData(true);
            }
        }
    }, [currencies, fetchingWithCpm]);

    useEffect(() => {
        if (isLoadedData) service.saveOrUpdateCampaignPreview();
    }, [isLoadedData]);

    useEffect(() => {
        try {
            if (timer === false) {
                const filterScreens = filteredScreensLocal.filter(
                    (filterScreen) => {
                        return newCampaign.selectedScreens.some(
                            (screen) => screen.id === filterScreen.id
                        );
                    }
                );

                dispatch(screensActions.setSelectedScreensLocal(filterScreens));
                dispatch(newCampaignActions.setSelectedScreens(filterScreens));
            }
        } catch (error) {
            //
        }
    }, [filteredScreensLocal]);

    return (
        <>
            <Helmet>
                <title>{`${t('common.menu.campaigns')} | ${
                    id
                        ? t('common.words.edit') + ' | ' + id
                        : t('common.words.new')
                }`}</title>
            </Helmet>
            <div className="fixed w-full h-screen flex bg-gray-100 overflow-hidden pb-10">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={'/dashboard'}
                    subSection={t('common.words.campaign')}
                    subSectionOne={
                        id ? t('common.words.edit') : t('common.words.new')
                    }
                />
                <div
                    className={`${
                        isResizeMapOn ? 'w-3/12' : 'w-full md:w-8/12'
                    } relative pl-16 pb-10 md:pt-0 mt-12 h-screen flex content-start bg-white border-r border-gray-300 shadow-lg transition-all duration-${resizeMapTransitionDuration}`}>
                    <WizardTab
                        setHasSchedules={setHasSchedules}
                        hasSchedules={hasSchedules}
                        calculatingForecast={calculatingForecast}
                    />
                    <div className="w-full h-screen mt-8 px-2 pt-4 pb-32">
                        {isLoadedData ? (
                            <>
                                <WizardBasics
                                    isActive={stepper.wizard === 'basics'}
                                    name={name}
                                    brand={brand}
                                    isResizeMapOn={isResizeMapOn}
                                    selectedCurrency={selectedCurrency}
                                    currencyDefault={currencyDefault}
                                    currencies={currencies}
                                    budget={budget}
                                    campaign={newCampaign}
                                    dailyCap={dailyCap}
                                    service={service}
                                    timing={timing}
                                    totalSpent={totalSpent}
                                    dispatch={dispatch}
                                    isTest={newCampaign.isTest}
                                />
                                <WizardLocation
                                    isActive={stepper.wizard === 'location'}
                                    zones={zones}
                                    isResizeMapOn={isResizeMapOn}
                                    service={service}
                                    location={location}
                                    selectedCurrency={selectedCurrency}
                                    filteredScreensLocal={filteredScreensLocal}
                                    setFilteredScreensLocal={
                                        setFilteredScreensLocal
                                    }
                                    newCampaign={newCampaign}
                                    dispatch={dispatch}
                                    screensWithoutFilter={mapedScreens}
                                    setSelectedScreensLocal={
                                        setSelectedScreensLocal
                                    }
                                    selectedScreensLocal={selectedScreensLocal}
                                    setFilteredScreensByLocation={
                                        setFilteredScreensByLocation
                                    }
                                    fetchingData={fetchingData}
                                    screensWithCpm={screensWithCpm}
                                    auth={auth}
                                    setLoadingDataTable={setLoadingDataTable}
                                    setTimer={setTimer}
                                    setFilterScreenByPoi={setFilterScreenByPoi}
                                    filterScreenByPoi={filterScreenByPoi}
                                />
                                {stepper.wizard === 'screens' && (
                                    <WizardScreens
                                        setScreenOpened={setScreenOpened}
                                        screenOpened={screenOpened}
                                        isActive={stepper.wizard === 'screens'}
                                        selectedScreensLocal={
                                            selectedScreensLocal
                                        }
                                        setSelectedScreensLocal={
                                            setSelectedScreensLocal
                                        }
                                        newCampaign={newCampaign}
                                        isResizeMapOn={isResizeMapOn}
                                        dispatch={dispatch}
                                        filteredScreensLocal={
                                            filteredScreensLocal
                                        }
                                        setFilteredScreensLocal={
                                            setFilteredScreensLocal
                                        }
                                        screensWithCpm={screensWithCpm}
                                        campaignTimezone={campaignTimezone}
                                        service={service}
                                        loadingDataTable={loadingDataTable}
                                        setLoadingDataTable={
                                            setLoadingDataTable
                                        }
                                        userMargin={userMargin}
                                        isFetchingData={fetchingData}
                                        fetchingWithCpm={fetchingWithCpm}
                                        userId={userId}
                                    />
                                )}
                                <WizardAudiences
                                    isActive={stepper.wizard === 'audiences'}
                                    useSelect={stepper.wizard === 'screens'}
                                    dispatch={dispatch}
                                    service={service}
                                    newCampaign={newCampaign}
                                    campaign={newCampaign}
                                    budgets={budgets}
                                    setSelectedScreensLocal={
                                        setSelectedScreensLocal
                                    }
                                    loadingDataTable={loadingDataTable}
                                    setLoadingDataTable={setLoadingDataTable}
                                    setCalculatingForecast={
                                        setCalculatingForecast
                                    }
                                    calculatingForecast={calculatingForecast}
                                />
                                <WizardCreatives
                                    isActive={stepper.wizard === 'creatives'}
                                    creatives={creatives}
                                    sizesNeeded={sizesNeeded}
                                    campaignId={campaignId}
                                    isResizeMapOn={isResizeMapOn}
                                    selectedScreens={selectedScreens}
                                    baseName={baseName}
                                    selectedCreativeCategories={
                                        selectedCreativeCategories
                                    }
                                    tracking={tracking}
                                    service={service}
                                    dispatch={dispatch}
                                />
                                <WizardReview
                                    userMargin={userMargin}
                                    isActive={stepper.wizard === 'review'}
                                    newCampaign={newCampaign}
                                    budgets={budgets}
                                    dispatch={dispatch}
                                    setHasSchedules={setHasSchedules}
                                    hasSchedules={hasSchedules}
                                    setCalculatingForecast={
                                        setCalculatingForecast
                                    }
                                />
                                {canLaunchCampaign && (
                                    <WizardLaunch
                                        isActive={stepper.wizard === 'launch'}
                                        service={service}
                                        newCampaign={newCampaign}
                                    />
                                )}
                            </>
                        ) : (
                            <Loading isLoading={true} />
                        )}
                    </div>
                    <div className="absolute z-30 flex justify-end bottom-1 right-1 mb-12 -ml-16 mr-0 w-auto border-l-0">
                        <div
                            onClick={() => handleWizard(stepper.index - 1)}
                            className={
                                (pageNumber === 0
                                    ? 'bg-white '
                                    : 'bg-blue-500 ') +
                                'w-7 h-7 rounded-full border bg-opacity-70 hover:bg-opacity-100 transition-all duration-200 cursor-pointer flex justify-center items-center'
                            }>
                            <FontAwesomeIcon
                                icon={['fal', `chevron-down`]}
                                className={
                                    pageNumber === 0
                                        ? 'transform rotate-90'
                                        : 'text-white transform rotate-90'
                                }
                            />
                        </div>
                        <div
                            onClick={() => handleWizard(stepper.index + 1)}
                            className={
                                (pageNumber === 5
                                    ? 'bg-white '
                                    : 'bg-blue-500 ') +
                                'w-7 h-7 rounded-full border bg-opacity-70 hover:bg-opacity-100 transition-all duration-200 cursor-pointer flex justify-center items-center'
                            }>
                            <FontAwesomeIcon
                                icon={['fal', `chevron-down`]}
                                className={
                                    pageNumber === 5
                                        ? 'transform -rotate-90'
                                        : 'text-white transform -rotate-90'
                                }
                            />
                        </div>
                    </div>
                    <WizardBriefBar
                        isActive={true}
                        selectedScreensLocal={selectedScreensLocal}
                    />
                </div>
                <div className="relative pt-10 md:pt-12 hidden md:flex flex-grow h-screen transition-all duration-300">
                    <div className="w-full p-0" id="google-map-canvas">
                        <MapCanvas
                            setScreenOpened={setScreenOpened}
                            screenOpened={screenOpened}
                            lat={defaultProps.center.lat}
                            lng={defaultProps.center.lng}
                            defaultZoom={defaultProps.zoom}
                            resetMap={resetMap}
                            setResetMap={setResetMap}
                            selectedScreensLocal={selectedScreensLocal}
                            screensWithCpm={screensWithCpm}
                            setSelectedScreensLocal={setSelectedScreensLocal}
                            newCampaign={newCampaign}
                            dispatch={dispatch}
                            setFilteredScreensLocal={setFilteredScreensLocal}
                            location={
                                mapLocations?.zones?.length != 0
                                    ? mapLocations
                                    : location
                            }
                            filteredScreensByLocation={
                                filteredScreensByLocation
                            }
                            filterScreenByPoi={filterScreenByPoi}
                        />
                    </div>
                    <div
                        onClick={() =>
                            dispatch(
                                newCampaignActions.setIsResizeMapOn(
                                    !isResizeMapOn
                                )
                            )
                        }
                        className="absolute z-30 hidden md:flex content-center top-0 left-0 mt-10 -ml-1.5 w-3 h-6 pt-1 rounded-full border border-gray-300 bg-gray-300 opacity-90 hover:opacity-100 hover:bg-white text-white hover:text-gray-400 cursor-pointer hover:shadow">
                        <FontAwesomeIcon
                            icon={['fal', `chevron-down`]}
                            className={`${
                                isResizeMapOn
                                    ? '-rotate-90 pr-0.5'
                                    : 'rotate-90 pl-0.5'
                            } text-xs transform`}
                        />
                    </div>
                </div>
            </div>

            <Modal
                size="sm"
                active={showModalWarning}
                toggler={toggleModalWarning}>
                <ModalHeader toggler={toggleModalWarning}>
                    {t('sections.campaigns.new-campaign.modal-warning.title') +
                        campaignNameWarning}
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {t(
                            'sections.campaigns.new-campaign.modal-warning.description'
                        )}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <CommonButton
                        onClick={onCancelWarning}
                        label={t('common.buttons.cancel')}
                        styleType={'secondary'}
                        className="mx-2"
                    />
                    <CommonButton
                        onClick={onAcceptWarning}
                        label={t('common.words.yes')}
                        styleType={'primary'}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CreateCampaignPage;
