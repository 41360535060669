import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions } from '../../store';
import { useAuth } from '../../containers/UseAuth';

function WizardTab({ setHasSchedules, hasSchedules, calculatingForecast }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const step = useSelector((state) => state.newCampaign.stepper.wizard);
    const isLaunching = useSelector((state) => state.newCampaign.isLaunching);
    const canLaunchCampaign = useSelector(
        (state) => state.newCampaign.canLaunch
    );

    const auth = useAuth();

    const handleStep = (name) => {
        if (calculatingForecast === false) {
            const blockWhileLaunching = [
                'basics',
                'location',
                'screens',
                'audiences',
                'creatives',
                'review',
            ];
            if (isLaunching && blockWhileLaunching.includes(name)) {
                return;
            } else {
                dispatch(newCampaignActions.setCurrentWizard(name));
            }
        }
    };

    return (
        <div className="tabCampaignContainer top backdrop transition-shadow duration-200 hover:shadow-lg ml-16">
            <div className="w-full flex justify-start overflow-x-auto scrollbar-thin scrollbar-black md:pl-5">
                <div className="h-8 w-8 pointer-events-none absolute top-0 left-0 ml-2 mt-1 z-10 bg-gradient-to-r from-gray-100"></div>
                <div className="h-8 w-8 pointer-events-none absolute top-0 right-0 mt-1 z-10 bg-gradient-to-l from-gray-100"></div>
                <div
                    className={`tabCampaign ${
                        step === 'basics' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('basics')}>
                    <FontAwesomeIcon
                        icon={['fad', `pen`]}
                        className={`${
                            step !== 'basics' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.basics')}
                </div>
                <div
                    className={`tabCampaign ${
                        step === 'location' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('location')}>
                    <FontAwesomeIcon
                        icon={['fad', `map-marker`]}
                        className={`${
                            step !== 'location' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.location')}
                </div>
                <div
                    className={`tabCampaign ${
                        step === 'screens' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('screens')}>
                    <FontAwesomeIcon
                        icon={['fad', `sign`]}
                        className={`${
                            step !== 'screens' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.screens')}
                </div>
                <div
                    className={`tabCampaign ${
                        step === 'audiences' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('audiences')}>
                    <FontAwesomeIcon
                        icon={['fad', `users-class`]}
                        className={`${
                            step !== 'audiences' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.audiences')}
                </div>
                <div
                    className={`tabCampaign ${
                        step === 'creatives' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('creatives')}>
                    <FontAwesomeIcon
                        icon={['fad', `palette`]}
                        className={`${
                            step !== 'creatives' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.creatives')}
                </div>
                <div
                    className={`tabCampaign ${
                        step === 'review' ? 'active' : ''
                    }`}
                    onClick={() => handleStep('review')}>
                    <FontAwesomeIcon
                        icon={['fad', `check-double`]}
                        className={`${
                            step !== 'review' ? 'opacity-50' : 'opacity-100'
                        } mr-1  text-xs`}
                    />{' '}
                    {t('sections.campaigns.new-campaign.wizard-tab.review')}
                </div>
                {canLaunchCampaign &&
                    (hasSchedules || auth?.session?.user?.cpm_mode == 0) && (
                        <div
                            className={`tabCampaign ${
                                step === 'launch' ? 'active button' : ''
                            }`}
                            onClick={() => handleStep('launch')}>
                            <FontAwesomeIcon
                                icon={['fad', `rocket-launch`]}
                                className={`${
                                    step !== 'launch'
                                        ? 'opacity-50'
                                        : 'opacity-100'
                                } mr-1  text-xs`}
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-tab.launch'
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
}

export default WizardTab;
