import LoginLayout from './LoginLayout/LoginLayout';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
    const { t } = useTranslation();
    const title = t('sections.maintenance.404-error');
    const redirectUrl = window.Config.REACT_APP_WL_URL;

    return (
        <>
            <Helmet>
                <title>{`${title} - ${window.Config.REACT_APP_WL_COMPANY_NAME}`}</title>
            </Helmet>
            <LoginLayout>
                <div className="text-gray-500 mb-12">
                    <h1 data-test="h1Title" className="font-bold text-4xl mb-2">
                        404
                    </h1>

                    <p>Page not found</p>
                </div>

                <form autoComplete="off">
                    <div className="my-4 flex text-center ">
                        <a
                            data-test="btnAccept"
                            className="capitalize bg-blue-400 hover:bg-blue-600 text-white rounded transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none w-full"
                            href={redirectUrl}>
                            {t('sections.login-page.back-login')}
                        </a>
                    </div>
                </form>
            </LoginLayout>
        </>
    );
};
