import React from 'react';
import { useTranslation } from 'react-i18next';
import { newCampaignActions } from '../../../../store';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Tracking = ({ isResizeMapOn, tracking, dispatch }) => {
    const { t } = useTranslation();

    const change = (attribute, value) => {
        const newTracking = Object.assign({}, tracking);
        newTracking[attribute] = value;
        dispatch(newCampaignActions.setTracking(newTracking));
    };

    const toggle = (attribute) => {
        change(attribute, !tracking[attribute]);
    };

    const tooltip = (
        <>
            <ReactTooltip
                effect="solid"
                className="w-48 p-2"
                id="explain-script-tracker"
                aria-haspopup="true">
                {t(
                    'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-tooltip'
                )}
            </ReactTooltip>
            <a data-tip data-for="explain-script-tracker">
                <FontAwesomeIcon
                    icon={['fad', 'info-circle']}
                    className="text-gray-700 text-sm"
                />
            </a>
        </>
    );

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-title'
                    )}
                </div>
                <div className="brief">
                    <SwitcherButton
                        onToggle={() => toggle('isActive')}
                        status={tracking.isActive}
                        labelOn={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOn'
                        )}
                        labelOff={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOff'
                        )}
                    />
                </div>
            </div>
            {tracking.isActive && (
                <>
                    <div className="body rounded-sm p-1">
                        <div
                            id="trackerWrapper"
                            className={`${
                                isResizeMapOn ? 'w-full' : 'md:w-3/6'
                            }`}>
                            <span className="block text-sm font-medium text-gray-700 mb-1">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-creatives.creatives.externalTracking-label'
                                )}{' '}
                                {tooltip}
                            </span>
                            <textarea
                                onChange={(e) =>
                                    change('externalTracking', e.target.value)
                                }
                                value={tracking.externalTracking}
                                className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"></textarea>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

Tracking.proptyTypes = {
    isResizeMapOn: PropTypes.bool,
    tracking: PropTypes.object,
    dispatch: PropTypes.func,
};

export default Tracking;
