import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../containers/UseAuth';
import LinkButton from '../../../Buttons/LinkButton';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cpmFromPrice, formatNumber } from '../../../../utils/functions';

const DownloadScreensToCSV = ({ userMargin }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedCurrencyShortName = newCampaign?.selectedCurrency?.short_name;
    const campaignId = newCampaign.id;
    const brand = newCampaign.brand;
    const campaignName = newCampaign.name;
    const selectedScreens = newCampaign.selectedScreens;
    const user = auth.session.user;
    const nameCSV =
        `Screens_${user.id}_${user.username}_${brand}_${campaignName}_${campaignId}`.replace(
            / /g,
            ''
        );

    const headers = [
        { label: 'Id', key: 'id' },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenName'
            ),
            key: 'name',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenPublisher'
            ),
            key: 'publisher.name',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenSize'
            ),
            key: 'size',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenStatus'
            ),
            key: 'status',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-screens.screens.table-screenCPM'
            ),
            key: 'ecpm',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.lat'
            ),
            key: 'lat',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.lon'
            ),
            key: 'lng',
        },
        {
            label: t(
                'sections.campaigns.new-campaign.wizard-review.blocks.screens.screen-details.hasAudience'
            ),
            key: 'hasAudience',
        },
    ];

    const formatedCsv = selectedScreens.map((screen) => {
        return {
            ...screen,
            ecpm: formatNumber(
                screen.ecpm
                    ? screen.ecpm
                    : cpmFromPrice(screen.deals, userMargin),
                'properCurrency',
                null,
                {
                    currency: selectedCurrencyShortName,
                }
            ),
        };
    });

    return (
        <CSVLink
            data={formatedCsv}
            headers={headers}
            filename={`${nameCSV}.csv`}>
            <FontAwesomeIcon
                icon={['fad', 'file-excel']}
                size="lg"
                className="text-green-500 cursor-pointer mr-2"
            />
            <LinkButton>
                {t(
                    'sections.campaigns.new-campaign.wizard-review.blocks.screens.download-screen-list'
                )}
            </LinkButton>
        </CSVLink>
    );
};

export default DownloadScreensToCSV;
