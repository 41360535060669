import React, { useEffect, useRef } from 'react';
import 'rc-slider/assets/index.css';
import Location from './Location/Location';
import NearPlace from './NearPlace/NearPlace';
import PropTypes from 'prop-types';
import Perf from '../../../utils/Perf';
import { useAuth } from '../../../containers/UseAuth';

function WizardLocation({
    screensWithoutFilter,
    isActive,
    zones,
    isResizeMapOn,
    location,
    selectedCurrency,
    dispatch,
    newCampaign,
    filteredScreensLocal,
    setFilteredScreensLocal,
    selectedScreensLocal,
    setSelectedScreensLocal,
    setFilteredScreensByLocation,
    auth,
    setLoadingDataTable,
    screensWithCpm,
    setTimer,
    setFilterScreenByPoi,
    filterScreenByPoi,
}) {
    const debounceRef = useRef(null);

    async function locationFilter(screens) {
        let filteredScreens = [];
        let newScreensSelected = [];
        const canSeeAudiences = auth?.session?.user?.cpm_mode === 0;
        const perf = new Perf('locationFilter');

        for (let i = 0; i < screens.length; i++) {
            let screen = screens[i];

            if (
                !selectedCurrency?.short_name ||
                !screen?.deals?.prices?.length > 0 ||
                !screen?.deals?.prices[0]?.currency ||
                screen?.deals?.prices[0]?.currency !==
                    selectedCurrency?.short_name
            ) {
                continue;
            }
            if (!canSeeAudiences && !screen.hasAudience) {
                continue;
            }

            const byCountry =
                location.selectedCountries.length > 0
                    ? location.selectedCountries.some(
                          (country) => country.value === screen.geo.country
                      )
                    : true;
            let byRegion = false;
            let byCity = false;
            let byNeighbours = false;
            if (location.selectedRegions.length > 0) {
                if ('area1' in screen.geo) {
                    byRegion = location.selectedRegions.some(
                        (region) => region.value === screen.geo.area1
                    );
                }
                if (!byRegion && 'area2' in screen.geo) {
                    byRegion = location.selectedRegions.some(
                        (region) => region.value === screen.geo.area2
                    );
                }
                if (!byRegion && 'area3' in screen.geo) {
                    byRegion = location.selectedRegions.some(
                        (region) => region.value === screen.geo.area3
                    );
                }
            } else {
                byRegion = true;
            }

            if (location.selectedCities.length > 0) {
                if ('locality1' in screen.geo) {
                    byCity = location.selectedCities.some((city) =>
                        city.value.includes(screen.geo.locality1)
                    );
                } else if ('area2' in screen.geo) {
                    byCity = location.selectedCities.some((city) =>
                        city.value.includes(screen.geo.area2)
                    );
                }
                if (!byCity && 'locality2' in screen.geo) {
                    byCity = location.selectedCities.some((city) =>
                        city.value.includes(screen.geo.locality2)
                    );
                }
            } else {
                byCity = true;
            }

            if (location.selectedNeighborhoods.length > 0) {
                if ('locality1' in screen.geo) {
                    byNeighbours = location.selectedNeighborhoods.some(
                        (neighbour) => neighbour.value === screen.geo.locality1
                    );
                }
                if (!byNeighbours && 'locality2' in screen.geo) {
                    byNeighbours = location.selectedNeighborhoods.some(
                        (neighbour) => neighbour.value === screen.geo.locality2
                    );
                }
            } else {
                byNeighbours = true;
            }

            if (byCountry && byRegion && byCity && byNeighbours) {
                filteredScreens.push(screen);
            }

            let oldSelected = selectedScreensLocal.find(
                (filterScreen) => filterScreen.id === screen.id
            );
            if (oldSelected) {
                newScreensSelected.push(oldSelected);
            }
        }

        //perf.out();

        setSelectedScreensLocal(newScreensSelected);
        return filteredScreens;
    }

    useEffect(async () => {
        if (debounceRef.current) {
            clearInterval(debounceRef.current);
        }

        setLoadingDataTable(true);
        //debounce used for keep loader working as long as the locations state are changing
        //prevent over render and filter unnecesary data
        debounceRef.current = setTimeout(async () => {
            if (auth.session.user.cpm_mode == 1 && !screensWithCpm.length) {
                return;
            }
            try {
                const screensFilteredByLocation = await locationFilter(
                    screensWithoutFilter
                );

                setFilteredScreensByLocation(screensFilteredByLocation);
                setFilteredScreensLocal(screensFilteredByLocation);
                setTimer(false);
                setFilterScreenByPoi(!filterScreenByPoi);
            } catch (error) {
            } finally {
                setLoadingDataTable(false);
            }
        }, 700);
    }, [
        screensWithoutFilter,
        location.selectedCities,
        location.selectedCountries,
        location.selectedNeighborhoods,
        location.selectedRegions,
    ]);

    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`basics mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-299 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20'
                            : ''
                    }`}>
                    <Location
                        location={location}
                        selectedCurrency={selectedCurrency}
                        dispatch={dispatch}
                    />
                    <NearPlace
                        zones={zones}
                        dispatch={dispatch}
                        newCampaign={newCampaign}
                        filteredScreensLocal={filteredScreensLocal}
                    />
                </div>
            )}
        </>
    );
}

WizardLocation.proptyTypes = {
    isActive: PropTypes.bool.isRequired,
    zones: PropTypes.object,
    isResizeMapOn: PropTypes.bool,
    service: PropTypes.func,
    location: PropTypes.object,
    selectedCurrency: PropTypes.object,
    dispatch: PropTypes.func,
    filteredScreens: PropTypes.array,
    newCampaign: PropTypes.object,
};

export default WizardLocation;
