import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { newCampaignActions } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../containers/UseAuth';
import {
    formatNumber,
    getValidatedPriceFromScreen,
    toFloat,
} from '../../../../utils/functions';
import { campaigScreensStatus } from '../../../../constants/endpoints';

const ValidationIssues = ({ dispatch, campaign, budgets, canLaunch }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);

    const auth = useAuth();
    const basicWizard = t('sections.campaigns.new-campaign.wizard-tab.basics');
    const locationWizard = t(
        'sections.campaigns.new-campaign.wizard-tab.location'
    );
    const screenWizard = t(
        'sections.campaigns.new-campaign.wizard-tab.screens'
    );
    const audiencesWizard = t(
        'sections.campaigns.new-campaign.wizard-tab.audiences'
    );
    const creativesWizard = t(
        'sections.campaigns.new-campaign.wizard-tab.creatives'
    );

    const basicWizardURL = 'basics';
    const screenWizardURL = 'screens';
    const creativesWizardURL = 'Creatives';

    const handleClickOnError = (wizard) => {
        dispatch(newCampaignActions.setCurrentWizard(wizard.toLowerCase()));
    };

    const between = (min, max, string) => {
        return string.trim().length >= min && string.length <= max;
    };

    const addError = (wizard, url, title, description, errors) => {
        const newError = { wizard, title, description, url };
        errors.push(newError);
    };

    const hasAtLeastOneChannel = () => {
        const channels = campaign.channels;
        return channels.isDOOH || channels.isMobile || channels.isTV;
    };

    const minValue = (min, number) => {
        if (number !== '') return number >= min;
        else return false;
    };

    const selectedAnyDayParting = () => {
        const cells = campaign.timing.isCellSelected.cells;
        for (let i = 0; i < cells.length; i++)
            for (let j = 0; j < cells[i].length; j++)
                if (cells[i][j]) return true;

        return false;
    };

    const selectedAnyDayOrHour = () =>
        campaign.timing.daysEnabled.length > 0 &&
        campaign.timing.hoursEnabled.length > 0;

    const hasSufficientFunds = () => {
        if (budgets) {
            const selectedCurrency = campaign.selectedCurrency.short_name;
            return (
                selectedCurrency in budgets && budgets[selectedCurrency] >= 0
            );
        } else return true;
    };

    const needAddCreative = () => {
        let sizes = campaign.sizesNeeded ?? [];
        let creatives = campaign.creatives ?? [];

        let creativesResponsive = creatives.filter((creative) => {
            return creative.original_filename
                ?.toLowerCase()
                .includes('responsive');
        });

        function matchSizes() {
            let matches = [];
            sizes.forEach((size) => {
                if (
                    creatives.some(
                        (creative) =>
                            `${creative.width}x${creative.height}` === size
                    )
                ) {
                    matches.push(true);
                } else {
                    matches.push(false);
                }
            });

            return matches.every((item) => item);
        }

        if (creativesResponsive.length) {
            return false;
        } else {
            if (matchSizes()) {
                return false;
            } else {
                return true;
            }
        }
    };

    const checkErrors = () => {
        const min = 3;
        const max = 255;
        const errors = [];
        const i18nErrors =
            'sections.campaigns.new-campaign.wizard-review.validation-issues.errors.';
        const i18nBasics = 'sections.campaigns.new-campaign.wizard-basics.';
        const i18nScreens = 'sections.campaigns.new-campaign.wizard-screens.';
        const i18nCreatives =
            'sections.campaigns.new-campaign.wizard-creatives.';
        const i18nAudiences =
            'sections.campaigns.new-campaign.wizard-audiences.';
        const budgetCpm = toFloat(campaign.budget.cpm);
        let locale = navigator.languages
            ? navigator.languages[0]
            : navigator.language;

        if (auth.session.user.cpm_mode === 0) {
            // FIXME: CPM refactor this when the calc is done in the backend
            // Hay una diferencia de sincronización que hace que a veces devuelva en
            // número y otras en string
            let minCpm;
            let cpmArr = [];
            if (
                campaign.selectedScreens.length > 0 &&
                getValidatedPriceFromScreen(campaign.selectedScreens[0])
            ) {
                //filter screens withOutPrice before using Math.min to avoid issues with undefined values
                let screensWithPrice = campaign.selectedScreens.filter(
                    (screen) => {
                        return getValidatedPriceFromScreen(screen);
                    }
                );
                if (screensWithPrice.length) {
                    minCpm = Math.min(
                        ...screensWithPrice.map((screen) => {
                            return parseFloat(
                                getValidatedPriceFromScreen(screen)
                            );
                        })
                    );
                }
            } else {
                if (locale !== 'en-US' && locale !== 'es-419') {
                    campaign.selectedScreens.forEach(function (screen) {
                        if (screen.cpm) {
                            //FIXME: when the page render and has no data in local storage the cpm value is undefined
                            let currencySymbol =
                                campaign.selectedCurrency.symbol;
                            let screenStr = String(screen.cpm);
                            let splitedCpm = screenStr
                                .replace(currencySymbol, '')
                                .replace('.', '')
                                .replace(',', '.');
                            cpmArr.push(toFloat(splitedCpm));
                        }
                    });
                } else {
                    campaign.selectedScreens.forEach(function (screen) {
                        if (screen.cpm) {
                            //FIXME: when the page render and has no data in local storage the cpm value is undefined
                            let currencySymbol =
                                campaign.selectedCurrency.symbol;
                            let screenStr = String(screen.cpm);
                            let splitedCpm = screenStr.replace(
                                currencySymbol,
                                ''
                            );
                            cpmArr.push(toFloat(splitedCpm));
                        }
                    });
                }

                minCpm = Math.min(...cpmArr);
            }

            if (budgetCpm < minCpm) {
                addError(
                    basicWizard,
                    basicWizardURL,
                    t(i18nBasics + 'budget.title'),
                    t(i18nErrors + 'lower-CPM'),
                    errors
                );
            }
        }

        //Basics errors
        if (!between(min, max, campaign.name)) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'naming.title'),
                t(i18nErrors + 'name'),
                errors
            );
        }
        if (!between(min, max, campaign.brand)) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'naming.title'),
                t(i18nErrors + 'brand'),
                errors
            );
        }
        // enable this validation when we have channels in backend
        // if (!hasAtLeastOneChannel())
        //     addError(basicWizard, basicWizardURL, t(i18nBasics + "channels.title"), t(i18nErrors + 'channel'), errors);
        if (!minValue(1, campaign.budget.budget)) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'budget'),
                errors
            );
        } else if (!hasSufficientFunds())
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'budget-insufficient'),
                errors
            );
        if (
            !campaign.budget.cpmOptimizer &&
            !minValue(1, campaign.budget.cpm)
        ) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'budget.title'),
                t(i18nErrors + 'CPM'),
                errors
            );
        }
        if (campaign.dailyCap.isActive && !campaign.dailyCap.isDistribution) {
            if (
                campaign.dailyCap.isBudget &&
                !minValue(1, campaign.dailyCap.budget)
            )
                addError(
                    basicWizard,
                    basicWizardURL,
                    t(i18nBasics + 'dailyCap.title'),
                    t(i18nErrors + 'daily-cap-budget'),
                    errors
                );
            if (
                campaign.dailyCap.isImpressions &&
                !minValue(1, campaign.dailyCap.impressions)
            )
                addError(
                    basicWizard,
                    basicWizardURL,
                    t(i18nBasics + 'dailyCap.title'),
                    t(i18nErrors + 'daily-cap-impressions'),
                    errors
                );
        }
        if (!campaign.timing.periodValue) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-campaign-duration'),
                errors
            );
        }
        if (campaign.timing.selectedTimezone === '') {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-timezone'),
                errors
            );
        }
        // When we use CustomTableDragSelect we need verify the data
        // if (campaign.timing.isDayParting && !selectedAnyDayParting())
        //     addError(basicWizard, basicWizardURL, t(i18nBasics + "timing.title"), t(i18nErrors + 'timing-day-parting'), errors);
        if (campaign.timing.isDayParting && !selectedAnyDayOrHour()) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'timing.title'),
                t(i18nErrors + 'timing-day-parting'),
                errors
            );
        }
        if (
            campaign.buyingRules.isActive &&
            campaign.buyingRules.rules.length === 0
        ) {
            addError(
                basicWizard,
                basicWizardURL,
                t(i18nBasics + 'buyingRules.title'),
                t(i18nErrors + 'buying-rules'),
                errors
            );
        }
        // Screens errors
        if (campaign.selectedScreens.length === 0) {
            addError(
                screenWizard,
                screenWizardURL,
                t(i18nScreens + 'screens.screens-title'),
                t(i18nErrors + 'screens'),
                errors
            );
        }
        // Creatives errors
        if (!between(min, max, campaign.baseName)) {
            addError(
                creativesWizard,
                creativesWizardURL,
                t(i18nCreatives + 'creatives.baseName-label'),
                t(i18nErrors + 'base-name'),
                errors
            );
        }
        if (needAddCreative()) {
            addError(
                creativesWizard,
                creativesWizardURL,
                t(i18nCreatives + 'creatives.title'),
                t(i18nErrors + 'sizes-needed'),
                errors
            );
        }
        if (
            campaign.tracking.isActive &&
            !between(min, max, campaign.tracking.externalTracking)
        ) {
            addError(
                creativesWizard,
                creativesWizardURL,
                t(i18nCreatives + 'creatives.externalTracking-title'),
                t(i18nErrors + 'tracking'),
                errors
            );
        }

        setErrors(errors);
        return errors;
    };

    useEffect(() => {
        let errors = checkErrors();
        dispatch(newCampaignActions.setCanLaunch(errors.length === 0));
    }, [campaign]);

    // useEffect(() => {
    // }, [errors]);

    return (
        <div className={`${canLaunch ? 'hidden' : ''} py-4`}>
            <FontAwesomeIcon
                icon={['fad', 'exclamation-triangle']}
                fixedWidth
                size="2x"
                className="mr-1 text-yellow-300"
            />{' '}
            <span className="font-light">
                {t(
                    'sections.campaigns.new-campaign.wizard-review.review.validation-issues'
                )}
            </span>
            <div className="mt-2">
                <ul className="list-none text-gray-500">
                    {errors.map((error, index) => (
                        <li key={index} className="py-1">
                            <span
                                onClick={() => handleClickOnError(error.url)}
                                className="text-md text-gray-300 cursor-pointer">
                                {error.wizard} /{' '}
                            </span>
                            <span className="text-lg text-gray-800">
                                {error.title}
                            </span>{' '}
                            {error.description}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ValidationIssues;
