import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { formatNumber } from '../../../utils/functions';
import DataTable from 'react-data-table-component';
import { getAvgCpm, getCurrenciesList } from '../../../services/pricingService';
import {
    COUNTRIES_OF_PROVIDERS,
    COUNTRIES_WITH_TIMEZONES,
    COUNTRY_CURRENCY_NAME,
} from '../../../constants/constants';
import UserSelector from '../../Input/UserSelector/UserSelector';
import { useAuth } from '../../../containers/UseAuth';

const PricingUser = ({ isActive, userId }) => {
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState(null);
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    let currencies = useSelector((state) => {
        return state.wallet.currencies;
    });
    const currencyOnLocalStorage = JSON.parse(
        localStorage.getItem('currencyDefault')
    );
    const [selectedCurrency, setSelectedCurrency] = useState({
        label: `${currencyOnLocalStorage?.short_name} - ${currencyOnLocalStorage?.name}`,
        value: currencyOnLocalStorage?.short_name,
        screenCurrency:
            COUNTRY_CURRENCY_NAME[currencyOnLocalStorage?.short_name],
    });

    const [currenciesPrices, setCurrenciesPrices] = useState(null);

    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [availableProviders, setAvailableProviders] = useState([]);

    currencies = currencies.map((currency) => {
        return {
            ...currency,
            label: `${currency.short_name} - ${currency.name}`,
            value: currency.short_name,
        };
    });
    useEffect(() => {
        if (!currenciesPrices) {
            getCurrenciesList().then((res) => {
                setCurrenciesPrices(res.data.conversion_rates);
            });
        }
    }, [currenciesPrices]);

    const countriesWithTimezones = COUNTRIES_WITH_TIMEZONES.filter(
        (country) => {
            return currencies.find((currency) => {
                return (
                    country?.value ===
                    COUNTRY_CURRENCY_NAME[currency?.short_name]
                );
            });
        }
    );

    const availableCountries = useMemo(() => {
        const uniqueCountries = [];

        countriesWithTimezones.forEach((country) => {
            if (!uniqueCountries.some((c) => c.value === country.value)) {
                uniqueCountries.push(country);
            }
        });

        return uniqueCountries;
    }, [countriesWithTimezones]);

    useEffect(() => {
        const availables = [];

        COUNTRIES_OF_PROVIDERS.forEach((provider) => {
            if (provider.countries.includes(selectedCountry?.value)) {
                provider.provider.forEach((providerName) => {
                    availables.push({
                        label: providerName,
                        value: providerName,
                    });
                });
            }
        });

        setAvailableProviders(availables);
    }, [selectedCountry]);

    useEffect(() => {
        const cpms = [];
        if (!selectedCountry || !selectedProvider) return;
        setIsLoading(true);
        console.log(selectedCurrency.value);
        getAvgCpm(
            selectedUser?.id || userId,
            selectedCountry?.value,
            selectedProvider?.value,
            selectedCurrency?.value
        )
            .then((res) => {
                res.data.forEach((sc) => {
                    cpms.push({
                        provider: res.data.provider,
                        screenType: sc.tier,
                        medianCpm: sc.average,
                    });
                });
                setData(cpms);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedCountry, selectedProvider, selectedUser, selectedCurrency]);

    useEffect(() => {
        if (!selectedProvider && availableProviders.length > 0) {
            setSelectedProvider(availableProviders[0]);
        }
    }, [availableProviders]);

    const handleChangeCurrency = (currency) => {
        if (currency.value === 'SOL') {
            setSelectedCurrency({
                label: currency.label,
                value: 'PEN',
                screenCurrency: 'PE',
            });
        } else {
            setSelectedCurrency({
                ...currency,
                screenCurrency: COUNTRY_CURRENCY_NAME[currency.value],
            });
        }
    };

    const handleSelectedUser = (user) => {
        setSelectedUser(user);
    };

    const handleChangeProvider = (provider) => {
        setSelectedProvider(provider);
    };

    const handleChangeCountry = (country) => {
        setSelectedCountry(country);
        setSelectedProvider(null);
    };

    const currentColumns = useMemo(() => {
        const columns = [
            {
                name: t(
                    'sections.management.section.pricing.tierList.columns.screenType'
                ),
                sortable: true,
                selector: (row) => {
                    return row.screenType;
                },
            },
            {
                name: 'Avg CPM',
                sortable: true,
                selector: (row) => {
                    return row.medianCpm;
                },
                cell: (row) => {
                    const convertedCpm = formatNumber(
                        row.medianCpm,
                        'properCurrency',
                        null,
                        {
                            currency: selectedCurrency.value,
                        }
                    );
                    return convertedCpm;
                },
            },
        ];
        return columns;
    }, [selectedCurrency.value, t, currenciesPrices]);

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
            <div
                className={`${
                    isActive ? 'block' : 'hidden'
                } w-full pl-4 pr-4 bg-opacity-90`}>
                <div className="block gap-8">
                    <div className="block w-full">
                        <label
                            htmlFor="country"
                            className="block text-gray-700 text-sm font-bold mb-2">
                            <FontAwesomeIcon
                                icon={['fal', 'globe']}
                                fixedWidth
                                className="text-blue-500"
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-briefBar.location.country'
                            )}
                            <p className="font-light">
                                {t('common.menu.pricing-user.country-label')}
                            </p>
                        </label>
                        <div className="my-2 w-full">
                            <Select
                                inputId="currency"
                                placeholder={t(
                                    'common.menu.pricing-user.country-label'
                                )}
                                options={availableCountries}
                                value={selectedCountry}
                                onChange={handleChangeCountry}
                            />
                        </div>
                    </div>
                    <div className="block w-full">
                        <label
                            htmlFor="provider"
                            className="block text-gray-700 text-sm font-bold mb-2">
                            <FontAwesomeIcon
                                icon={['fal', 'users-class']}
                                fixedWidth
                                className="text-blue-500"
                            />{' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.audience-provider.title'
                            )}
                            <p className="font-light">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.audience-provider.select-value'
                                )}
                            </p>
                        </label>
                        <div className="my-2 w-full">
                            <Select
                                inputId="provider"
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.audience-provider.select-novalue'
                                )}
                                options={availableProviders}
                                value={selectedProvider}
                                onChange={handleChangeProvider}
                            />
                        </div>
                    </div>
                    {selectedCountry &&
                        selectedProvider &&
                        currenciesPrices && (
                            <div className="block w-full">
                                <label
                                    htmlFor="currency"
                                    className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon
                                        icon={['fal', 'coin']}
                                        fixedWidth
                                        className="text-blue-500"
                                    />{' '}
                                    {t(
                                        'sections.management.section.pricing.common.currency.title'
                                    )}
                                    <p className="font-light">
                                        {t(
                                            'sections.management.section.pricing.common.currency.label'
                                        )}
                                    </p>
                                </label>
                                <div className="my-2 w-full">
                                    <Select
                                        inputId="currency"
                                        placeholder={t(
                                            'sections.management.section.billing.funding.currencySelect'
                                        )}
                                        options={currencies}
                                        value={selectedCurrency}
                                        onChange={handleChangeCurrency}
                                    />
                                </div>
                            </div>
                        )}
                    {selectedCountry &&
                        selectedProvider &&
                        currenciesPrices &&
                        auth.isAdminOrSupervisor() && (
                            <div className="block w-full">
                                <label
                                    htmlFor="currency"
                                    className="block text-gray-700 text-sm font-bold mb-2">
                                    <FontAwesomeIcon
                                        icon={['fal', 'user']}
                                        fixedWidth
                                        className="text-blue-500"
                                    />{' '}
                                    {t('sections.management.section.users.tab')}
                                    <p className="font-light">
                                        {t(
                                            'sections.management.section.users.users.searchPlaceholder'
                                        )}
                                    </p>
                                </label>
                                <div className="my-2 w-full">
                                    <UserSelector
                                        idCustom="userName"
                                        value={selectedUser}
                                        onChange={handleSelectedUser}
                                    />
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {isLoading && (
                <div className="flex items-center gap-2 w-8 mx-auto">
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className="text-blue-400 animate-spin"
                    />
                    <p className="text-gray-500">
                        {t('common.words.loading')}...
                    </p>
                </div>
            )}

            {data && !isLoading && (
                <DataTable columns={currentColumns} data={data} />
            )}
        </div>
    );
};

export default PricingUser;
