import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Button from '../../components/Buttons/CommonButton';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as endpoints from '../../constants/endpoints';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../store';
import { Helmet } from 'react-helmet-async';
import useDispatchNotification from '../../hooks/notifications/useDispatchNotification';

function ScreenApproval({}) {
    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const publisher = encodeURIComponent(query.get('publisher'));
    const campaignInfo = query.get('campaignInfo');
    const emailResponded = query.get('email');
    let { operation, campaignid, token } = useParams();
    const { errorNotification, successNotification } =
        useDispatchNotification();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [title] = useState(
        operation === 'approve'
            ? t('sections.screenApproval.message-approve-title')
            : t('sections.screenApproval.message-reject-title')
    );

    let strOperation =
        operation === 'approve'
            ? t('sections.screenApproval.approve')
            : t('sections.screenApproval.reject');
    const getErrorMessage = (numberErrror, whoResponded, statusMail) => {
        const statuses = {
            1: t('sections.screenApproval.approved'),
            2: t('sections.screenApproval.rejected'),
            3: t('sections.screenApproval.new-mail'),
        };
        const statusOnError = statuses[statusMail];
        if (statusMail === '3') return statusOnError;
        const errorsMessage = {
            1: t('sections.screenApproval.err-params-invalid'),
            2: t('sections.screenApproval.err-responded', {
                email: whoResponded,
                status: statusOnError,
            }),
            3: t('sections.screenApproval.err-token-expired'),
        };

        if (whoResponded === 'user' || whoResponded === 'admin') {
            errorsMessage[numberErrror] = errorsMessage[numberErrror].replace(
                `by ${whoResponded}`,
                ''
            );
        }
        return errorsMessage[numberErrror];
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        let url = `${endpoints.backendV2}/screens/${operation}/${campaignid}/${token}?publisher=${publisher}&email=${emailResponded}`;
        setIsLoading(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    successNotification(
                        title,
                        t('sections.screenApproval.ok-message')
                    );
                } else {
                    response.json().then((responseJson) => {
                        const errorMessage = getErrorMessage(
                            responseJson.numberError,
                            responseJson.whoResponded,
                            responseJson.statusMail
                        );
                        errorNotification(title, errorMessage);
                    });
                }
            })
            .catch((err) => {
                console.log('err: ', err);
                errorNotification(
                    title,
                    t('sections.screenApproval.err-message')
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>{`${title} - ${window.Config.REACT_APP_WL_COMPANY_NAME}`}</title>
            </Helmet>
            <LoginLayout>
                <div className="text-gray-500 mb-12">
                    <h1 data-test="h1Title" className="font-bold text-3xl mb-2">
                        {publisher
                            ? decodeURIComponent(publisher)
                            : 'Publisher'}
                    </h1>
                    <p data-test="pDescription">
                        {t('sections.screenApproval.really', {
                            operation: strOperation,
                            campaign: campaignid,
                        })}
                        <b className="font-bold lowercase">
                            {' '}
                            #{campaignInfo ? campaignInfo : campaignid}
                        </b>
                        ?
                    </p>
                </div>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="my-4 flex text-center ">
                        <Button
                            dataTest="btnAccept"
                            label={
                                operation === 'approve'
                                    ? t('sections.screenApproval.approve')
                                    : t('sections.screenApproval.reject')
                            }
                            styleType={
                                operation === 'approve' ? 'primary' : 'danger'
                            }
                            className="w-full"
                        />
                        <a
                            data-test="aCancel"
                            className="capitalize bg-white hover:bg-gray-200 text-black rounded border border-gray-200 transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none ml-2 w-full"
                            styleType={'secondary'}
                            href="https://www.taggify.net/">
                            {t('common.buttons.cancel')}
                        </a>
                        <FontAwesomeIcon
                            icon={['fas', 'circle-notch']}
                            className={`text-blue-400 animate-spin mx-2 ${
                                !isLoading && 'hidden'
                            }`}
                        />
                    </div>
                </form>
            </LoginLayout>
        </>
    );
}

export default ScreenApproval;
