import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from '@react-pdf/renderer';
import { getMedia } from './base64logos';
import ScreenTable from './ScreenTable';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

Font.register({
    family: 'serif',
    fonts: [
        {
            src: 'http://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOj3wa5GD2qnm.ttf',
        },
    ],
});

const environment = window.Config.REACT_APP_WL_COMPANY_NAME;

const iso = getMedia(environment, 'iso');
const ooh = getMedia(environment, 'ooh');
const isologo = getMedia(environment, 'isologo');

const stylesheet = StyleSheet.create({
    page: {
        backgroundColor: '#FFF',
        fontWeight: 'bold',
        padding: '20px',
    },
    header: {
        position: 'relative',
        padding: 35,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    container: {
        display: 'inline',
        position: 'relative',
        paddingLeft: '20px',
    },
    label: {
        fontSize: '12pt',
    },
    ooh: {
        width: environment === 'Taggify' ? '45%' : '35%',
        position: 'absolute',
        top: environment === 'Taggify' ? -30 : 0,
        right: 0,
    },
    iso: {
        height: '42',
        width: '42',
        borderRadius: '2px',
        position: 'absolute',
        top: 10,
        left: 20,
    },
    references: {
        marginTop: '14px',
        fontSize: '14pt',
        fontWeight: 'bold',
    },
    footer: {
        position: 'absolute',
        maxWidth: '100px',
        height: 'auto',
        textAlign: 'center',
        bottom: 15,
        right: 0,
        left: 0,
        marginLeft: '25px',
    },
    circle: {
        backgroundColor: '#60A5FA',
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
        height: '8px',
        width: '8px',
        top: '2px',
        left: 0,
    },
});

const PoisPdf = ({
    markers,
    colors,
    screens,
    selectedScreens,
    setIsLoadPdf,
}) => {
    const { t } = useTranslation();
    if (colors.length === 0) return <Document></Document>;
    const markersLength = markers.length;
    const googleMapsApiKey = 'AIzaSyBfLH4V6D19dTDTW31weSPHhKN-LxDQcR4';

    // La api de google tiene un limitante de caracteres de 8192
    let url;
    if (markers) {
        //TODO: Poner la imagen correcta del dsp de Bebot
        url = `https://maps.googleapis.com/maps/api/staticmap?size=500x300&${markers}&scale=2&style=feature:poi%7Cvisibility:off&style=feature:road%7Csaturation:-100%7Clightness:45&style=feature:road.highway%7Cvisibility:simplified&style=feature:road.arterial%7Cvisibility:on&style=feature:road.local%7Cvisibility:on&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:administrative.locality%7Cvisibility:off&zoom=${
            markersLength < 60 ? 13 : ''
        }&key=${googleMapsApiKey}`;
    } else {
        url = '';
    }

    return (
        <Document>
            <Page size="A4" style={stylesheet.page}>
                <View style={stylesheet.header}>
                    <Image src={iso} style={stylesheet.iso} fixed />
                    {environment === 'Taggify' && (
                        <Image src={ooh} style={stylesheet.ooh} fixed />
                    )}
                </View>
                <View style={stylesheet.section}>
                    <Text>
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.nearPlace-placeholder'
                        )}
                    </Text>
                    <Image src={url} style={{ marginTop: 10 }} />
                    <Text style={stylesheet.references}>
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.colors'
                        )}
                        :{' '}
                    </Text>
                    <View style={{ marginTop: '14px' }}>
                        {colors &&
                            colors.map((color, i) => {
                                return (
                                    <View key={i}>
                                        <View style={stylesheet.container}>
                                            <Text
                                                key={i}
                                                style={{
                                                    backgroundColor:
                                                        color.color,
                                                    display: 'block',
                                                    position: 'absolute',
                                                    borderRadius: '50%',
                                                    height: '12px',
                                                    width: '12px',
                                                    top: '2px',
                                                    left: 0,
                                                }}>
                                                {' '}
                                            </Text>
                                            <Text style={stylesheet.label}>
                                                {color.label}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}
                    </View>
                </View>
                <Image src={isologo} style={stylesheet.footer} fixed />
            </Page>
            <Page size="A4" style={stylesheet.page}>
                <View style={stylesheet.header}>
                    <Text>
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.screens-match'
                        )}
                        :
                    </Text>
                    <View
                        style={{
                            display: 'inline',
                            position: 'relative',
                            paddingLeft: '10px',
                            marginTop: '12px',
                        }}>
                        <Text style={stylesheet.circle}> </Text>
                        <Text style={{ fontSize: '10pt' }}>
                            {' '}
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.location.screens-selected-placeholder'
                            )}
                        </Text>
                    </View>
                    <View style={{ marginTop: '20px' }}>
                        {screens && (
                            <ScreenTable
                                data={screens}
                                selectedScreens={selectedScreens}
                            />
                        )}
                    </View>
                </View>
                <Image src={isologo} style={stylesheet.footer} fixed />
            </Page>
        </Document>
    );
};

ScreenTable.propTypes = {
    markers: PropTypes.string,
    colors: PropTypes.string,
    screens: PropTypes.array,
    selectedScreens: PropTypes.array,
};

export default PoisPdf;
