import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../../../containers/UseService';
import UserSelector from '../../../../../../Input/UserSelector/UserSelector';
import Input from '../Input/Input';
import InputSelect from '../InputSelect/InputSelect';
import InputRadio from '../InputsRadio/InputRadio';
import { isValidInput } from '../../../../../../../utils/functions';
import Hint from '../../../../../../Tools/Hint';
import SwitcherButton from '../../../../../../Buttons/CommonSwitcher';

const Basic = (props) => {
    const { translations, user, setUser, typesUsers, edit, companies, roles } =
        props;
    const { t } = useTranslation();
    const service = useService();
    const noValidation = () => true;

    const validateName = (value) => {
        // need help here
        return value.length > 0 && value != null && typeof value == 'string';
    };

    const change = (key, value) => {
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy[key] = value;
        setUser(userCopy);
    };

    const onchangeMargin = (value) => {
        const newValue = parseFloat(value).toFixed(3) >= 1 ? 0.0 : value;
        if (isNaN(value) || (newValue > 0.99 && newValue < 0)) {
            change('margin', user.margin);
            return;
        }
        change('margin', isNaN(newValue) ? 0 : newValue);
    };

    const checkConfirmPassword = () => {
        return user.confirmPassword !== ''
            ? user.password === user.confirmPassword
            : true;
    };

    useEffect(checkConfirmPassword, [user.password]);

    const handleChangeParentAccount = () => {
        const newIsParentAccount = !user.isParentAccount;
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy.isParentAccount = newIsParentAccount;

        if (newIsParentAccount) {
            userCopy.roleSelected = null;
        } else {
            userCopy.userSelected = null;
        }

        setUser(userCopy);
    };

    const handleChangeHasCompany = () => {
        const newHasCompany = !user.hasCompany;
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy.hasCompany = newHasCompany;

        if (!newHasCompany) {
            userCopy.company = null;
        }

        setUser(userCopy);
    };

    const validateUsername = async (username) => {
        if (edit && username === user.originalUsername) {
            return true;
        } else {
            return await service.isValidUsername(username);
        }
    };
    return (
        <div className="w-11/12 grid-cols-1 gap-x-14 sm:gap-y-4  sm:grid sm:grid-cols-2 ">
            <Input
                label={t(translations + 'username')}
                id="username"
                change
                value={user.username}
                onChange={(username) => change('username', username)}
                onValidate={validateUsername}
                placeholder={t(translations + 'usernamePlaceholder')}
                errorMessage={t(translations + 'usernameAlreadyExists')}
            />
            <Input
                label={t(translations + 'email')}
                id="email"
                value={user.email}
                onChange={(email) => change('email', email)}
                onValidate={() => isValidInput(user.email, 'email')}
                placeholder={t(translations + 'emailPlaceholder')}
                errorMessage={t(translations + 'invalidEmail')}
            />
            <InputSelect
                label={t(translations + 'typeTxt')}
                id="type-users"
                options={typesUsers}
                value={user.typeUser}
                onChange={(typeUser) => change('typeUser', typeUser)}
                placeholder={t(translations + 'typePlaceholder')}
            />
            <Input
                label={t(translations + 'margin')}
                id="margin"
                value={user.margin}
                onChange={(margin) => onchangeMargin(margin)}
                onValidate={noValidation}
                placeholder={t(translations + 'marginPlaceholder')}>
                <div className="relative">
                    <Hint
                        text={t(translations + 'margin-hint')}
                        className="absolute bottom-0 left-14"
                    />
                </div>
            </Input>
            <Input
                label={t(translations + 'name')}
                id="name"
                value={user.name}
                onChange={(name) => change('name', name)}
                onValidate={() => validateName(user.name)}
                placeholder={t(translations + 'namePlaceholder')}
                errorMessage={t(translations + 'cannotBeBlank')}
            />
            <Input
                type="text"
                label={t(translations + 'phoneNumber')}
                id="phone-number"
                value={user.phoneNumber}
                onChange={(phoneNumber) => change('phoneNumber', phoneNumber)}
                onValidate={() => isValidInput(user.phoneNumber, 'phone')}
                placeholder={t(translations + 'phoneNumberPlaceholder')}
                errorMessage={t(translations + 'invalidPhoneNumber')}
            />
            {edit && (
                <InputRadio
                    value={user.wantChangePassword}
                    onChange={() =>
                        change('wantChangePassword', !user.wantChangePassword)
                    }
                    name="change-password"
                    label={t(translations + 'changePassword')}
                />
            )}
            {/* if is editing and want to change the password or if not editing */}
            {(!edit || (edit && user.wantChangePassword)) && (
                <>
                    <Input
                        autoComplete="new-password"
                        type="password"
                        label={t(translations + 'password')}
                        id="password"
                        value={user.password}
                        onChange={(password) => change('password', password)}
                        onValidate={noValidation}
                        placeholder={t(translations + 'passwordPlaceholder')}
                    />
                    <Input
                        autoComplete="new-password"
                        type="password"
                        label={t(translations + 'confirmPassword')}
                        id="confirm-password"
                        value={user.confirmPassword}
                        onChange={(confirmPassword) =>
                            change('confirmPassword', confirmPassword)
                        }
                        onValidate={checkConfirmPassword}
                        placeholder={t(
                            translations + 'confirmPasswordPlaceholder'
                        )}
                        errorMessage={t(translations + 'passwordNotMatch')}
                    />
                </>
            )}

            <div className="w-3/6 ml-4">
                <label className="block mb-4">
                    <span className="text-gray-700">
                        {t(translations + 'isParentAccount')}
                    </span>
                    <div className="mt-2">
                        <SwitcherButton
                            onToggle={() => handleChangeParentAccount()}
                            status={user.isParentAccount}></SwitcherButton>
                    </div>
                </label>
            </div>

            {user.isParentAccount ? (
                <div className="max-w-sm">
                    <label className="text-sm font-medium text-gray-700">
                        {t(translations + 'parentAccount')}
                    </label>
                    <div className="grid grid-cols-7">
                        <div className="grid col-span-6">
                            <UserSelector
                                value={user.userSelected}
                                onChange={(userSelected) =>
                                    change('userSelected', userSelected)
                                }
                            />
                        </div>
                        <div
                            className={`flex justify-center items-center col-span-1 ${
                                !user.userSelected ? 'invisible' : ''
                            }`}>
                            <FontAwesomeIcon
                                icon={['fas', 'check']}
                                fixedWidth
                                className="mr-1 text-green-500"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <InputSelect
                    label={t(translations + 'roleTxt')}
                    id="roles"
                    options={roles}
                    value={user.roleSelected}
                    onChange={(roleSelected) =>
                        change('roleSelected', roleSelected)
                    }
                    placeholder={t(translations + 'rolePlaceholder')}
                />
            )}
            <div className={`${user.hasCompany ? '' : 'col-span-2'} `}>
                <div className="w-3/6 ml-4">
                    <label className="block mb-4">
                        <span className="text-gray-700">
                            {t(translations + 'hasCompany')}
                        </span>
                        <div className="mt-2">
                            <SwitcherButton
                                onToggle={handleChangeHasCompany}
                                status={user.hasCompany}></SwitcherButton>
                        </div>
                    </label>
                </div>
            </div>
            {user.hasCompany && (
                <InputSelect
                    label={t(translations + 'company')}
                    id="companies"
                    options={companies}
                    value={user.company}
                    onChange={(company) => change('company', company)}
                    placeholder={t(translations + 'companyPlaceholder')}
                />
            )}

            <div className="w-3/6 ml-4">
                <label className="block mb-4">
                    <span className="text-gray-700">
                        {t(translations + 'showOohOption')}
                    </span>
                    <div className="mt-2">
                        <SwitcherButton
                            onToggle={() =>
                                change('showOohOption', !user.showOohOption)
                            }
                            status={user.showOohOption}></SwitcherButton>
                    </div>
                </label>
            </div>
            <div className="w-3/6 ml-4">
                <label className="block mb-4">
                    <span className="text-gray-700">
                        {t(translations + 'enable-cpm-mode')}
                    </span>
                    <div className="mt-2">
                        <SwitcherButton
                            onToggle={() => change('cpm_mode', !user.cpm_mode)}
                            status={user.cpm_mode}></SwitcherButton>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default Basic;
