let baseURL = window.Config.REACT_APP_GATEWAY_URL;
export const base = baseURL;

export const getUsers = baseURL + '/users';
export const getUserById = baseURL + '/users/';
export const createUser = baseURL + '/users';
export const editUser = baseURL + '/users/';
export const userSsp = baseURL + '/users/';
export const getSsps = baseURL + '/users/ssp';
export const deleteSsp = baseURL + '/users/';
export const asignSsps = baseURL + '/users/';
export const assignSspToUser = baseURL + '/users/';
export const urlActivateUser = (id) => {
    return baseURL + '/users/' + id + '/activate';
};
export const urlDeactivateUser = (id) => {
    return baseURL + '/users/' + id + '/deactivate';
};
export const getCommercialContacts = baseURL + '/users/';
export const getCampaigns = baseURL + '/campaigns';
//export const getCities = baseURL + '/cities';
export const getScreens = baseURL + '/screens';
export const getScreensVast = baseURL + '/api/backendv2/screens/vast/list';
export const uploadFiles = baseURL + '/ad';
export const assignSize = baseURL + '/ad/';
export const duplicateAd = baseURL + '/ad/clone';
export const removeAd = baseURL + '/ad/';
export const updateSizeAd = baseURL + '/ad';
export const getMargins = baseURL + '/adjcoef';
export const getCompanies = baseURL + '/companies';
export const getCommercialsPersons = baseURL + '/users/company-person';
export const createCommercialPerson = baseURL + '/users/company-person';
export const editCommercialPerson = baseURL + '/users/company-person/';
export const removeCommercialPerson = baseURL + '/users/company-person/';
export const getUpdates = '/version.json';

export const getTokenV2 = baseURL + '/api/backendv2/users/getToken';
export const finishCampaignV2 = baseURL + '/api/backendv2/campaign/finish/';
export const activateCampaignV2 = baseURL + '/api/backendv2/campaign/finish/';
export const deactivateCampaignV2 =
    baseURL + '/api/backendv2/campaign/deactivate/';
export const getCampaignPreviewV2 = baseURL + '/api/backendv2/campaign';
export const removeCampaignV2 = baseURL + '/api/backendv2/campaign/';
export const duplicateCampaignV2 = baseURL + '/api/backendv2/campaign/';
export const assignBudgetToCampaignV2 = baseURL + '/api/backendv2/campaign/';
export const getCampaignFiltersV2 = baseURL + '/api/backendv2/campaign/';
export const getCampaignV2 = baseURL + '/api/backendv2/campaign/';
export const assignDailyCapLimitToCampaignV2 =
    baseURL + '/api/backendv2/campaign/';
export const assignCpmToCampaignV2 = baseURL + '/api/backendv2/campaign/';
export const restoreCampaignV2 = baseURL + '/api/backendv2/campaign/restore/';
export const superviseV2 = baseURL + `/api/backendv2/users/supervise`;
export const forgotPasswordV2 =
    baseURL + '/api/backendv2/users/forgot-password';
export const resetPasswordV2 = baseURL + '/api/backendv2/users/reset-password';
export const addBillingV2 = baseURL + '/api/backendv2/billing';
export const getUserMovements =
    baseURL + '/api/backendv2/billing/user/movements';
export const getBudgetsV2 = baseURL + '/api/backendv2/campaign/budgets';
export const getBudgetsDetailsV2 =
    baseURL + '/api/backendv2/campaign/budgets/details';
export const getCampaignsByUserIdV2 =
    baseURL + '/api/backendv2/campaign/campaignsByUserId/';
export const getCampaignsPerStatusV2 =
    baseURL + '/api/backendv2/campaign/campaignsPerStatus';
export const getCurrenciesV2 = baseURL + '/api/backendv2/campaign/currencies';
export const getTimezonesV2 = baseURL + '/api/backendv2/campaign/timezones';
export const getCountriesV2 = baseURL + '/api/backendv2/campaign/countries';
export const getGeoNodesV2 = baseURL + '/api/backendv2/geo/nodes';
export const getGeoCountriesV2 = baseURL + '/api/backendv2/geo/countries';
export const saveCampaignPreviewV2 = baseURL + '/api/backendv2/campaign';
export const userSettingsV2 = baseURL + '/api/backendv2/users/user-settings/';
export const getFeaturesForUserV2 = baseURL + '/api/backendv2/features';

export const uploadTester = baseURL + '/upload-tester';
export const getDensity = baseURL + '/api/stats/v2/screens/';
export const getForecaster = baseURL + '/api/stats/v2/campaigns/forecaster';
export const getForecastGraph = baseURL + '/audiences/audienceComposition';
export const getReportByCampaign = baseURL + '/api/report/campaigns/';
export const getReportByCampaigns = baseURL + '/api/report/campaigns';
export const getCampaignStatusPrediction =
    baseURL + '/api/stats/v2/campaigns/prediction/';
export const reportsByUserType = baseURL + '/api/stats/v2/reports';
export const reportsData = baseURL + '/api/stats/v2/reports';
export const calulateImpacts = baseURL + '/api/query';
export const invoices = baseURL + '/api/backendv2/invoices';
export const getAdminInvoices = baseURL + '/api/backendv2/invoices/admin';
export const getUserInvoices = baseURL + '/api/backendv2/invoices/user';
export const backendV2 = baseURL + '/api/backendv2';
export const getStats = baseURL + '/api/stats/v2/stats';
export const campaigScreensStatus = baseURL + '/api/backendv2/screens/status';
export const campaignsScreensStatus =
    baseURL + '/api/backendv2/campaigns/status';
export const screensWithSpotCost =
    baseURL + '/api/stats/v2/stats/reports/screens';
export const screensReportsData = baseURL + '/api/stats/v2/stats/screens/data';
export const screensReportsWithCpm =
    baseURL + '/api/stats/v2/stats/screens/withcpm';
export const getProviders = baseURL + '/providers';

// Tier endpoints
export const createTierPrice = baseURL + '/api/backendv2/pricing/tier/create';
export const updateTierPrice = baseURL + '/api/backendv2/pricing/tier/update';
export const deleteTierPrice = baseURL + '/api/backendv2/pricing/tier/delete';
export const getCurrenciesPrices =
    baseURL + '/api/backendv2/pricing/currencies';
export const getTierTypes = baseURL + '/api/backendv2/pricing/tier/types';
export const getTierList = baseURL + '/api/backendv2/pricing/tier/list';
export const getAvgCpms = baseURL + '/api/stats/v2/stats/screens/avg';
export const getCountriesByProviders =
    baseURL + '/api/stats/v2/screens/providers';
