/**
 *
 * Contenedor de la lista de campañas. Contiene las páginas de
 * borrador, creación, edición, eliminadas y pendientes.
 *
 */
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import CommonButton from '../../components/Buttons/CommonButton';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CampaingsList from '../../components/CampaignList/CampaignList';
import { useHistory } from 'react-router-dom';
import {
    capitalizeFirstLetter,
    fromLinkPathToStatus,
    fromStatusToLinkPath,
} from '../../utils/functions';
import ContinueCampaignModal from '../../components/Header/ContinueCampaignModal';
import { Helmet } from 'react-helmet-async';

import { useService } from '../../containers/UseService';

function CampaignList(params) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const statusFromPathUrl = fromLinkPathToStatus(
        location.pathname.split('/')[location.pathname.split('/').length - 1]
    );
    const campaigns = useSelector((state) => state.campaigns);
    const isLoading = campaigns.isLoading;
    const [orderedStates, setOrderedStates] = useState([]);
    const statuses = campaigns.statuses ? campaigns.statuses : [];
    const current = campaigns.campaignsShowing;

    const useName = () => {
        let state;

        const object = {
            DRAFT: t(`common.campaign.statuses.DRAFT`),
            ACTIVE: t('common.campaign.statuses.ACTIVE'),
            INACTIVE: t('common.campaign.statuses.INACTIVE'),
            DELETED: t('common.campaign.statuses.DELETED'),
            JOB_PENDING: t(`common.campaign.statuses.JOB_PENDING`),
            JOB_REJECTED: t(`common.campaign.statuses.JOB_REJECTED`),
            JOB_SUCCESS: t('common.campaign.statuses.JOB_SUCCESS'),
        };

        if (current.status in object) {
            state = object[current.status];
        } else {
            state = current.status;
        }

        return state;
    };

    const setOrderListOfStatus = () => {
        let orderStates = [];
        Object.keys(statuses).forEach((status, index) => {
            const statusName = formatCampaignStatus(status);
            orderStates.push({
                name: statusName,
                value: status,
                amount: statuses[status],
            });
        });

        orderStates.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setOrderedStates(orderStates);
    };

    useEffect(setOrderListOfStatus, [statuses]);

    const formatCampaignStatus = (status) =>
        status !== ''
            ? t('sections.campaign-list.' + status + '-campaigns')
            : '';

    const changeStatus = (status) => {
        if (!isLoading) {
            history.push(`/campaigns/status/${fromStatusToLinkPath(status)}`);
        }
    };

    const service = useService();
    const [isCampaignOpen, setIsCampaignOpen] = useState(false);
    const [showModalContinueCampaign, setShowModalContinueCampaign] =
        useState(false);
    const toggleModalContinueCampaign = () =>
        setShowModalContinueCampaign(!showModalContinueCampaign);

    const toggleMenu = () => setIsCampaignOpen(!isCampaignOpen);
    const onClickNewCampaign = () => {
        setIsCampaignOpen(!isCampaignOpen);
        if (service.hasDraftCampaignInLocalStorage()) {
            setShowModalContinueCampaign(true);
        } else {
            goToNewCampaign();
        }
    };

    const goToNewCampaign = () => {
        const routeToNewCampaign = '/campaign/new';
        const routeToEditCampaign = '/campaign/edit';

        if (location.pathname.includes(routeToEditCampaign)) {
            window.location.href = routeToNewCampaign;
        } else {
            if (location.pathname === routeToNewCampaign) {
                history.go(); //refresh
            } else {
                history.push('/campaign/new');
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>{`${t(
                    'common.menu.campaigns'
                )} | ${capitalizeFirstLetter(useName().toLowerCase())}`}</title>
            </Helmet>
            <div className="fixed h-screen w-full bg-gray-100 overflow-y-auto pb-10">
                <Header
                    currentSection={t('common.menu.dashboard')}
                    sectionPath={'/dashboard'}
                    subSection={t('common.words.campaigns')}
                    subSectionOne={t(
                        'common.campaign.statuses.' + current.status
                    )}
                />
                <div className="workingArea flex justify-between w-full mt-10 md:mt-16">
                    <div className="font-extralight text-2xl p-2 leading-5">
                        {formatCampaignStatus(current.status)} <br />
                        {'ACTIVE' in statuses && 'JOB_SUCCESS' in statuses && (
                            <span className="text-sm text-gray-600 font-light">
                                {t('sections.campaign-list.you-have')}{' '}
                                <span className="font-medium text-green-500">
                                    {statuses['ACTIVE']}{' '}
                                    {t(
                                        'sections.campaign-list.active-campaigns'
                                    )}
                                </span>{' '}
                                {statuses['DRAFT']}{' '}
                                {t('sections.campaign-list.draft-campaigns')}{' '}
                                {t('common.words.and')}{' '}
                                {statuses['JOB_SUCCESS']}{' '}
                                {t('sections.campaign-list.finished')}.
                            </span>
                        )}
                    </div>
                    <div className="text-right mb-2">
                        {/* FIXME: Crear componente de botón  testeable */}
                        <button
                            className="mt-4 capitalize bg-blue-400 hover:bg-blue-600 text-white rounded-sm transition-colors duration-300 ease-in-out font-light text-sm p-1 py-2 px-2 lg:px-4 focus:outline-none"
                            onClick={() => onClickNewCampaign()}>
                            {t('sections.campaign-list.create-campaign')}
                        </button>
                    </div>
                </div>
                <div className="workingArea gap-2 flex flex-col md:flex-row md:justify-between">
                    <div className="module w-full md:w-3/12">
                        <ul className="place-content-evenly space-y-2 font-light">
                            {orderedStates.map((status, index) => (
                                <li
                                    key={index}
                                    onClick={() => changeStatus(status.value)}
                                    className={`text-blue-500 cursor-pointer flex justify-between`}>
                                    <span>{status.name}</span>{' '}
                                    <span className="text-right text-gray-400">
                                        {status.amount}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <CampaingsList
                        status={statusFromPathUrl}
                        containerClassName={'relative module w-full md:w-9/12'}
                    />
                </div>
            </div>
            <ContinueCampaignModal
                goToNewCampaign={goToNewCampaign}
                isActive={showModalContinueCampaign}
                toggler={toggleModalContinueCampaign}
            />
        </>
    );
}

export default CampaignList;
