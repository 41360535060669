import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tab from './Tab.js';
import { useService } from '../../../../containers/UseService';

const Providers = () => {
    const service = useService();
    const newCampaign = useSelector((state) => state.newCampaign);
    const selectedCountries = newCampaign.location.selectedCountries;
    const demographics = newCampaign.demographics;

    const isAvailableCountry = (availableCountries) => {
        return availableCountries.some((country) =>
            selectedCountries.some(
                (selectedCountry) => selectedCountry.value === country
            )
        );
    };

    const handleClickTab = (provider) => {
        service.getProviderMetadata(provider);
    };

    const [availableProviders, setAvailableProviders] = useState([]);

    useEffect(() => {
        if (!availableProviders.includes(demographics.id)) {
            setAvailableProviders((prevProviders) => [
                ...prevProviders,
                demographics.id,
            ]);
        }
    }, [demographics]);

    const providers = [
        {
            title: 'Onemata',
            name: 'onemata',
            availableCountries: ['ZA', 'CL', 'MX', 'CO', 'AR', 'CR', 'PA', 'SV', 'GT', 'HN', 'EC'],
        },
        {
            title: 'Scopesi',
            name: 'scopesi',
            availableCountries: ['AR', 'PY'],
        },
        {
            title: 'Cinnecta',
            name: 'cinnecta',
            availableCountries: ['BR'],
        },
    ];

    const providersArray = providers.map((item) => {
        return {
            title: item.title,
            name: item.name,
            isActive: newCampaign.demographics.id === item.name,
            providerIsAvailable: newCampaign.selectedScreens.some((screen) => {
                return screen.audiences?.[item.name];
            }),
            countryHasProvider: isAvailableCountry(item.availableCountries),
        };
    });

    const fallbackProvider = providersArray.filter(
        (item) => item.countryHasProvider === true
    )[0];

    useEffect(() => {
        handleClickTab(
            newCampaign.demographics.id !== ''
                ? newCampaign.demographics.id
                : fallbackProvider?.name
        );
    }, []);

    return (
        <>
            {providersArray
                .filter((item) => item.countryHasProvider)
                .map((item, index) => {
                    return (
                        <Tab
                            key={index}
                            isAvaible={true}
                            isActive={item.isActive}
                            title={item.title}
                            onClick={() => handleClickTab(item.name)}
                            hidden={true}
                        />
                    );
                })}
        </>
    );
};

export default Providers;
