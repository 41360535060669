import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    //Wizard basics
    name: '',
    brand: '',
    channels: {
        isDOOH: true,
        isMobile: false,
        isTV: false,
    },
    budget: {
        budget: 0,
        cpm: 0,
        cpmOptimizer: true,
    },
    dailyCap: {
        isActive: false,
        isDistribution: false,
        isBudget: true,
        budget: 0,
        isImpressions: false,
        impressions: 0,
    },
    timing: {
        isDayParting: false,
        periodValue: null,
        selectedTimezone: '',
        timezones: [],
        isAsap: true,
        isCellSelected: {
            cells: [
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
                [
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                ],
            ],
        },
        daysEnabled: [],
        hoursEnabled: [],
    },
    buyingRules: {
        isActive: false,
        buyIf: '',
        rules: [],
    },
    selectedCurrency: null,

    // Wizard Location
    location: {
        nodes: [],
        countries: [],
        selectedCountries: [],
        regions: [],
        selectedRegions: [],
        cities: [],
        selectedCities: [],
        neighborhood: [],
        selectedNeighborhoods: [],
        zones: [],
    },

    mapLocations: {
        nodes: [],
        countries: [],
        selectedCountries: [],
        regions: [],
        selectedRegions: [],
        cities: [],
        selectedCities: [],
        neighborhood: [],
        selectedNeighborhoods: [],
        zones: [],
    },

    // Wizard Screens
    selectedScreens: [],
    showSelectedScreens: false,
    selectedScreenTypes: [],
    selectedPublishers: [],
    // Wizard Audiences
    demographics: { id: 'onemata' },
    segments: {
        activeSegment: 'age',
        age: { value: [], sliderValue: [0, 99] },
        gender: [],
        incomeLevel: [],
        interests: [],
        accuracy: { value: 'mid', sliderValue: 1 },
    },
    forecast: {
        targetUniverse: '-',
        totalImpacts: '-',
        coverage: '-',
        coverageAbs: '-',
        adImpressions: '-',
        targetImpacts: 0,
        frequency: '-',
        cpm: '-',
        grp: '-',
        screens: '-',
        duration: '-',
        validScreens: [],
        screensNotUsed: [],
        screensUsed: [],
        notMatchingScreens: [],
        screensWithoutProvider: [],
        costs: '-',
        isUnderspend: false,
    },
    forecastTable: [],
    // Wizard Creatives
    baseName: '',
    sizesNeeded: [],
    tracking: {
        isActive: false,
        externalTracking: '',
    },
    totalSpent: 0,
    creatives: [],
    selectedCreativeCategories: [],
    errors: [],
    canLaunch: false,
    isResizeMapOn: false,
    stepper: { index: 0, wizard: 'basics' },
    currentWizard: 'basics',
    wasLaunched: false,
    currentPositionMap: null,
    status: '',
    isTest: false,
    isLaunching: false,
};

const { reducer, actions } = createSlice({
    name: 'newCampaign',
    initialState: initialState,
    reducers: {
        setMany(state, action) {
            let newDataCampaign = action.payload;
            state.totalSpent = newDataCampaign.totalSpent;
            state.name = newDataCampaign.name;
            state.id = newDataCampaign.id ? newDataCampaign.id : null;
            state.brand = newDataCampaign.brand;
            state.channels = newDataCampaign.channels;
            state.budget = newDataCampaign.budget;
            state.dailyCap = newDataCampaign.dailyCap;
            state.buyingRules = newDataCampaign.buyingRules;
            state.forecast = newDataCampaign.forecast;
            state.forecastTable = newDataCampaign.forecastTable;
            state.location = newDataCampaign.location;
            state.mapLocations = newDataCampaign.mapLocations;
            state.selectedScreens = newDataCampaign.selectedScreens;
            state.sizesNeeded = newDataCampaign.sizesNeeded;
            state.baseName = newDataCampaign.baseName;
            state.creatives = newDataCampaign.creatives;
            state.selectedCreativeCategories =
                newDataCampaign.selectedCreativeCategories;
            state.errors = newDataCampaign.errors;
            state.canLaunch = newDataCampaign.canLaunch;
            state.timing = newDataCampaign.timing;
            state.segments = newDataCampaign.segments;
            state.tracking = newDataCampaign.tracking;
            state.selectedCurrency = newDataCampaign.selectedCurrency;
            state.demographics = newDataCampaign.demographics;
            state.isTest = newDataCampaign.isTest;
        },
        setId(state, action) {
            state.id = action.payload;
        },
        setNodeAndCountries(state, action) {
            state.location.nodes = action.payload.nodes;
            state.location.countries = action.payload.countries;
        },
        setSelectedCountries(state, action) {
            state.location.selectedCountries = action.payload;
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setBrand(state, action) {
            state.brand = action.payload;
        },
        setChannels(state, action) {
            state.channels = action.payload;
        },
        setBudget(state, action) {
            state.budget = action.payload;
        },
        setDailyCap(state, action) {
            state.dailyCap = action.payload;
        },
        setTiming(state, action) {
            const { id } = state.timing.selectedTimezone;
            if (id) {
                if (id !== action.payload.selectedTimezone?.id) {
                    action.payload.periodValue = null;
                }
            }
            state.timing = action.payload;
        },
        setBuyingRules(state, action) {
            state.buyingRules = action.payload;
        },
        setSelectedCurrency(state, action) {
            state.selectedCurrency = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setMapLocations(state, action) {
            state.mapLocations = action.payload;
        },
        setSelectedScreens(state, action) {
            state.selectedScreens = action.payload;
        },
        setShowSelectedScreens(state, action) {
            state.showSelectedScreens = action.payload;
        },
        setSelectedScreenTypes(state, action) {
            state.selectedScreenTypes = action.payload;
        },
        setSelectedPublishers(state, action) {
            state.selectedPublishers = action.payload;
        },
        setDemographics(state, action) {
            state.demographics = action.payload;
        },
        setSegments(state, action) {
            state.segments = action.payload;
        },
        setForecast(state, action) {
            state.forecast = action.payload;
        },
        setScreensWithouProvider(state, action) {
            state.forecast.screensWithoutProvider = action.payload;
        },
        setForecastTable(state, action) {
            state.forecastTable = action.payload;
        },
        setBaseName(state, action) {
            state.baseName = action.payload;
        },
        setSizesNeeded(state, action) {
            state.sizesNeeded = action.payload;
        },
        setTracking(state, action) {
            state.tracking = action.payload;
        },
        setCreatives(state, action) {
            state.creatives = action.payload;
        },
        setSelectedCreativeCategories(state, action) {
            state.selectedCreativeCategories = action.payload;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        setCanLaunch(state, action) {
            state.canLaunch = action.payload;
        },
        setIsResizeMapOn(state, action) {
            state.isResizeMapOn = action.payload;
        },
        setStepper(state, action) {
            state.stepper = action.payload;
        },
        setCurrentWizard(state, action) {
            state.currentWizard = action.payload;
        },
        setWasLaunched(state, action) {
            state.wasLaunched = action.payload;
        },
        setCurrentPositionMap(state, action) {
            state.currentPositionMap = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setIsTest(state, action) {
            state.isTest = action.payload;
        },
        setLocationZonesRadius(state, action) {
            const { i, j, value } = action.payload;
            state.location.zones[i].results[j].radius = value;
        },
        setIsLaunching(state, action) {
            state.isLaunching = action.payload;
        },
        setLocationZonesCenterToInitialValue(state, action) {
            const { i, j, lat, lng } = action.payload;
            state.location.zones[i].results[j].lat = lat;
            state.location.zones[i].results[j].lng = lng;
        },
        reset(state, action) {
            Object.keys(initialState).forEach((key) => {
                state[key] = initialState[key];
            });
        },
    },
});

export { actions as newCampaignActions };
export { reducer as newCampaignReducer };
