import React, { useEffect, useState } from 'react';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import { useTranslation } from 'react-i18next';
import { newCampaignActions } from '../../../../store';
import {
    isNumber,
    formatNumber,
    numberWithoutCommas,
} from '../../../../utils/functions';

const DailyCap = ({ selectedCurrency, dailyCap, dispatch }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [currencySpace, setCurrencySpace] = useState('pl-7');

    const change = (attribute, value) => {
        const newDailyCap = Object.assign({}, dailyCap);
        newDailyCap[attribute] = value;
        dispatch(newCampaignActions.setDailyCap(newDailyCap));
    };

    const toggle = (attribute) => {
        change(attribute, !dailyCap[attribute]);
    };

    const handleInputChangeNumber = (number) => {
        if (number === '') {
            number = '0';
        }

        if (isNumber(number)) {
            number = numberWithoutCommas(number);
            number = number === '0' ? '' : number;

            if (dailyCap.isBudget) {
                change('budget', number);
            } else {
                change('impressions', number);
            }
        }
    };

    const handleChangeSelect = (value) => {
        const newDailyCap = Object.assign({}, dailyCap);
        switch (value) {
            case 'budget':
                newDailyCap.isBudget = true;
                newDailyCap.isImpressions = false;
                break;
            case 'impressions':
                newDailyCap.isBudget = false;
                newDailyCap.isImpressions = true;
                break;
            default:
                break;
        }

        dispatch(newCampaignActions.setDailyCap(newDailyCap));
    };

    useEffect(() => {
        const parseNumber = (number) => {
            number = formatNumber(number, 'withcommas');
            number = number === '0' ? '' : number;
            return number;
        };

        if (dailyCap.isBudget) {
            setInputValue(parseNumber(dailyCap.budget));
        } else {
            setInputValue(parseNumber(dailyCap.impressions));
        }
    }, [dailyCap]);

    useEffect(() => {
        if (selectedCurrency) {
            const amountCharacters = selectedCurrency.symbol.length;
            switch (amountCharacters) {
                case 1:
                    setCurrencySpace('pl-7');
                    break;
                case 2:
                    setCurrencySpace('pl-8');
                    break;
                case 3:
                    setCurrencySpace('pl-11');
                    break;
                case 4:
                    setCurrencySpace('pl-14');
                    break;
                default:
                    setCurrencySpace('pl-7');
            }
        }
    }, [selectedCurrency]);

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.dailyCap.title'
                    )}
                </div>
                <div className="brief">
                    <SwitcherButton
                        onToggle={() => toggle('isActive')}
                        status={dailyCap.isActive}
                        labelOn={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOn'
                        )}
                        labelOff={t(
                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.dailyCap-labelOff'
                        )}
                    />
                </div>
            </div>

            <div
                className={`${
                    dailyCap.isActive ? '' : 'hidden'
                } body rounded-sm p-1`}>
                <div className="flex justify-start flex-col md:flex-row mb-2">
                    <div className="w-full md:w-3/6 mr-0 mt-4 md:mt-0 md:mr-4">
                        <label className="block mb-4">
                            <span className="text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.dailyCap.distribution-label'
                                )}
                            </span>
                            <div className="mt-2">
                                <SwitcherButton
                                    revertLabel={true}
                                    onToggle={() => toggle('isDistribution')}
                                    status={dailyCap.isDistribution}
                                    labelOn={t(
                                        'sections.campaigns.new-campaign.wizard-basics.dailyCap.distribution-labelOn'
                                    )}
                                    labelOff={t(
                                        'sections.campaigns.new-campaign.wizard-basics.dailyCap.distribution-labelOff'
                                    )}
                                />
                            </div>
                        </label>
                    </div>
                    <div
                        className={`${
                            dailyCap.isDistribution ? 'invisible' : ''
                        } md:w-3/6 md:ml-4`}>
                        <label
                            htmlFor="budget"
                            className="block text-sm font-medium text-gray-700">
                            {dailyCap.isBudget
                                ? t(
                                      'sections.campaigns.new-campaign.wizard-basics.dailyCap.budget-label'
                                  )
                                : t(
                                      'sections.campaigns.new-campaign.wizard-basics.dailyCap.impressions-label'
                                  )}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            {dailyCap.isBudget && (
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">
                                        {selectedCurrency &&
                                            selectedCurrency.symbol}
                                    </span>
                                </div>
                            )}
                            <input
                                type="text"
                                name="daily-budget"
                                autoComplete="off"
                                onChange={(e) =>
                                    handleInputChangeNumber(e.target.value)
                                }
                                value={inputValue}
                                id="daily-budget"
                                className={`${
                                    dailyCap.isBudget && 'pl-7'
                                } focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md ${currencySpace}`}
                                placeholder={`${
                                    dailyCap.isBudget ? '0.00' : '0'
                                }`}
                            />

                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <label htmlFor="cap-type" className="sr-only">
                                    {dailyCap.isBudget
                                        ? t(
                                              'sections.campaigns.new-campaign.wizard-basics.dailyCap.budget-label'
                                          )
                                        : t(
                                              'sections.campaigns.new-campaign.wizard-basics.dailyCap.impressions-label'
                                          )}
                                </label>
                                <select
                                    id="cap-type"
                                    value={
                                        dailyCap.isBudget
                                            ? 'budget'
                                            : 'impressions'
                                    }
                                    onChange={(e) =>
                                        handleChangeSelect(e.target.value)
                                    }
                                    name="cap-type"
                                    className="focus:ring-0 focus:outline-none h-full py-0 pl-2 pr-7 border-none bg-transparent text-gray-500 sm:text-sm rounded-md">
                                    <option value="budget" defaultChecked>
                                        {t(
                                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.budget-label'
                                        )}
                                    </option>
                                    <option value="impressions">
                                        {t(
                                            'sections.campaigns.new-campaign.wizard-basics.dailyCap.impressions-label'
                                        )}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyCap;
