import moment from 'moment-timezone';
import { COUNTRIES_WITH_PROVIDER } from '../constants/constants';

export const getPeriods = (array) => {
    let sorted = array.sort((a, b) => {
        return a - b;
    });
    let am = sorted.filter((hour) => {
        return hour < 13;
    });
    let pm = sorted
        .filter((hour) => {
            return hour >= 13;
        })
        .map((hour) => {
            return hour - 12;
        });
    if (am.includes(0)) {
        am = am.filter((hour) => {
            return hour !== 0;
        });
        pm.push(12);
    }

    am = getRanges(am);
    pm = getRanges(pm);

    return { am, pm };
};

/**
 * For some reason I don't understand (and I'm not willing to...) Safari Browser doesn't support Javascript Date
 * So we created this function to format date according to the browser
 * @param {*} date a date in any format
 * @returns a date formatted acording to the browser
 */
export const formatDateAccordingToBrowser = (date) => {
    let locale = navigator.languages
        ? navigator.languages[0]
        : navigator.language;
    const isSafariBrowser =
        navigator.userAgent.indexOf('Safari') > -1 &&
        navigator.userAgent.indexOf('Chrome') <= -1;
    let dateFormatted = '';
    isSafariBrowser
        ? (dateFormatted = moment(date).format('l'))
        : (dateFormatted = new Date(date).toLocaleDateString(locale, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }));

    return dateFormatted;
};

// check if key pressed is one number or not
export const isNumber = (inputValue) => {
    const lastCharacter = inputValue.slice(-1);
    return lastCharacter >= '0' && lastCharacter <= '9';
};

/**
 * Trim a number to 2 decimals.
 *
 * @param {number} number - The number to trim.
 *
 * @returns {number} The trimmed number.
 *
 * @deprecated   22.04.2 Use formatNumber(number, format) instead
 */
export const lastTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
};

/**
 * Converts a number to a float with 2 decimal places in english locale.
 *
 * Checks if the number passed is a number and if it is a number it converts it to a string float with
 * 2 decimal places. Otherwise it returns the string "0".
 *
 * @deprecated   22.04.1 Use formatNumber(number, format) instead
 */
export const numberWithCommas = (number) => {
    // Return raw number allways
    if (number === 0 || number === '') {
        return '0';
    } else {
        return parseFloat(number).toLocaleString('en');
    }
};

export const numberWithCommasInArrayObjectsRecursive = (
    arrayWithObjects,
    validKeys,
    customModifier
) => {
    arrayWithObjects.forEach((object) => {
        return Object.keys(object).forEach((key) => {
            const value = object[key];
            const isValidKey = validKeys ? validKeys.includes(key) : true;

            if (Array.isArray(value)) {
                object[key] = numberWithCommasInArrayObjectsRecursive(
                    value,
                    validKeys,
                    customModifier
                );
            } else if (value && typeof value === 'object') {
                const [newValue] = numberWithCommasInArrayObjectsRecursive(
                    [value],
                    validKeys,
                    customModifier
                );
                object[key] = newValue;
            } else if (typeof value === 'number' && isValidKey) {
                if (value !== '0' && value !== 0) {
                    if (customModifier) {
                        object[key] = customModifier(value);
                    } else {
                        object[key] = value === 0 ? 0 : numberWithCommas(value);
                    }
                }
            }
        });
    });
    return arrayWithObjects;
};

export const isPeriodSelectedInRangeOfCampaign = (period, campaign) => {
    const isValidFrom = new Date(period[0]) <= new Date(campaign.end_period);
    const isValidTo = new Date(period[1]) >= new Date(campaign.start_period);
    return isValidFrom && isValidTo;
};

/**
 * Converts a number to a float without commas.
 *
 * @deprecated   22.04.2 Use formatNumber(number, format) instead
 */
export const numberWithoutCommas = (number) => {
    if (number) {
        if (typeof number === 'number') {
            return number;
        } else {
            return number.length === 0 ? 0 : number.replace(/,/g, '');
        }
    }

    return 0;
};

export const getCampaignDurationByPeriodValue = (period) => {
    let campaignDuration = '';

    if (period) {
        const date1 = new Date(period[0]);
        const date2 = new Date(period[1]);
        const diffTime = Math.abs(date2 - date1);
        campaignDuration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return campaignDuration;
    } else {
        return -1;
    }
};

export const fromStatusToLinkPath = (status) => {
    return status.toLowerCase().replace(/_/, '-');
};

export const fromLinkPathToStatus = (linkPath) => {
    return linkPath.toUpperCase().replace(/-/, '_');
};

export const isNowPM = () => {
    return new Date().getHours() >= 12;
};

export const isNowPMAbsolute = () => {
    return new Date().getHours() > 12;
};

/**
 * It takes a string of the format eg.: "12pm-2pm", and returns true if the current hour is between 12pm
 * and 2pm in the example
 * @param hourRange - The range of hours that is to be evaluated.
 * @returns A boolean value
 */
export const checkHourRange = (hourRange) => {
    let today = new Date();
    let rangeStart;
    let rangeEnd;
    let currentHour =
        today.getHours() > 12 ? today.getHours() - 12 : today.getHours();
    let range = hourRange;
    rangeStart = range.trim().slice(0, range.indexOf('-') - 1);
    rangeEnd = range.trim().slice(range.indexOf('-') + 1, range.length);
    let isNowInRange = rangeStart <= currentHour && currentHour <= rangeEnd;

    return isNowInRange;
};

export const getTotalCampaignsByStatuses = (statuses) => {
    let total = 0;
    Object.keys(statuses).forEach((key) => {
        total += statuses[key];
    });

    return total;
};

export const colorRandom = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
};

// add color values if the zone havent it
export const setZoneColor = (zones) => {
    zones &&
        zones.map((zone) => {
            const color = zone.results[0].color
                ? zone.results[0].color
                : colorRandom();
            if (!zone.color) {
                zone.color = color;
                for (let r of zone.results) {
                    r.color = color;
                }
            } else {
                for (let r of zone.results) {
                    r.color = zone.color;
                }
            }
            return zone;
        });
    return zones;
};

// must have label attribute to work
export const arrayToList = (selectArray) => {
    let list = '';
    selectArray.map((item) => {
        return (list += item.label + ', ');
    });
    list = list.slice(0, -2);
    return list;
};

export const isEmpty = (str) => {
    return str !== null ? !str.replace(/\s+/, '').length : true;
};

export const formatScreenLocation = (screen) => {
    return `${screen.geo.route ? screen.geo.route : ''} ${
        screen.geo.number ? screen.geo.number + ',' : ''
    } ${screen.geo.area2 ? screen.geo.area2 + ',' : ''} ${
        screen.geo.area1 ? screen.geo.area1 : ''
    }`;
};
export const screenLat = (screen) => {
    return `${screen.geo.lat ? screen.geo.lat : '-'}`;
};

export const screenLon = (screen) => {
    return `${screen.geo.lon ? screen.geo.lon : '-'}`;
};

export const getResolutionByString = (string) => {
    const splited = string.split('x');
    return splited.length === 2 ? splited : [0, 0];
};

const getRanges = (array) => {
    let ranges = [];
    let rstart;
    let rend;
    for (var i = 0; i < array.length; i++) {
        rstart = array[i];
        rend = rstart;
        while (array[i + 1] - array[i] === 1) {
            rend = array[i + 1];
            i++;
        }
        ranges.push(
            rstart === rend ? rstart + '' : ' ' + rstart + ' - ' + rend
        );
    }
    return ranges;
};

export const millisecondsToDate = (dateMilli) => {
    let datetime = dateMilli;
    let date = new Date(datetime);
    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    let result = date.toLocaleDateString('en', options);
    return result;
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDaysFromNumbers(arrayNumbers) {
    const days = new Array(7);
    days[7] = 'Sun';
    days[1] = 'Mon';
    days[2] = 'Tue';
    days[3] = 'Wed';
    days[4] = 'Thu';
    days[5] = 'Fri';
    days[6] = 'Sat';
    const weekDays = [];
    arrayNumbers.forEach((i) => {
        //currently we are using 7 as sunday but some draft campaigns are still saved with 0 as sunday
        weekDays.push(days[i == 0 ? 7 : i]);
    });

    return weekDays;
}

export function fillWithDays() {
    return Array.from(Array(8).keys()).slice(1);
}

export function fillWithHours() {
    return Array.from(Array(24).keys());
}

export function sortDays(days) {
    return days.sort((a, b) => {
        const addZeroIfLessTen = (arr) => {
            arr[0] = arr[0].length === 1 ? '0' + arr[0] : arr[0];
            arr[1] = arr[1].length === 1 ? '0' + arr[1] : arr[1];
            return arr.reverse().join('');
        };
        a = a.day.split('/');
        b = b.day.split('/');
        a = addZeroIfLessTen(a);
        b = addZeroIfLessTen(b);
        return a > b ? 1 : a < b ? -1 : 0;
    });
}

export function sortHours(hours) {
    hours.forEach((stat) => {
        stat.hour = parseInt(stat.hour);
    });
    return hours.sort((a, b) => {
        return a.hour - b.hour;
    });
}

export function isByHourCurrentPeriod(currentPeriod) {
    const from = new Date(currentPeriod[0]);
    const to = new Date(currentPeriod[1]);
    const diffTime = Math.abs(to - from);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1;
}

export function replaceAllDashesWithSlashes(arr, key) {
    arr.forEach((el) => {
        el[key] = el[key].replace('-', '/');
    });
    return arr;
}

export function getUserRoles() {
    return ['admin', 'manager', 'advertiser', 'slave', 'supervisor'];
}

export function roleUserToString(roleUser) {
    switch (roleUser) {
        case 1:
            return 'admin';
        case 2:
            return 'manager';
        case 3:
            return 'advertiser';
        case 4:
            return 'slave';
        case 5:
            return 'supervisor';
        default:
            return 'slave';
    }
}

export function stringToRoleUser(string) {
    switch (string) {
        case 'admin':
            return 1;
        case 'manager':
            return 2;
        case 'advertiser':
            return 3;
        case 'slave':
            return 4;
        case 'supervisor':
            return 5;
        default:
            return 4;
    }
}

export function getTypeUsers() {
    return ['publisher', 'advertiser', 'both'];
}

export function typeUserToString(typeUser) {
    switch (typeUser) {
        case 0:
            return 'publisher';
        case 1:
            return 'advertiser';
        case 2:
            return 'both';
        default:
            return 'publisher';
    }
}

export function stringToTypeUser(string) {
    switch (string) {
        case 'publisher':
            return 0;
        case 'advertiser':
            return 1;
        case 'both':
            return 2;
        default:
            return 0;
    }
}

/**
 * Modify one array by putting the keys 'label' and 'value' in each object to put in one select
 * @param {Array} array of items, each item must be Object
 * @param {String} labelKey the key of the object that will be used as label
 * @param {String} valueKey the key of the object that will be used as value
 * @returns {Array} modified with 'label' and 'value' in each object
 */
export function setLabelAndValue(array, labelKey, valueKey) {
    return array.map((item) => {
        return { ...item, label: item[labelKey], value: item[valueKey] };
    });
}

/**
 * Change one date to another timezone
 * @param {Date} date
 * @param {String} timezoneName name of the target timezone
 * @returns {Date} with timezone changed
 */
export function setOtherZone(date, timezoneName) {
    const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
    const otherZone = moment.tz(date, timezoneName).format('Z');
    const dateWithOtherZone = [dateWithoutZone, otherZone].join('');

    return new Date(dateWithOtherZone);
}

/**
 * Change one date to the local timezone of browser
 * @param {Date} date
 * @param {String} timezoneName the timezone of the date
 * @returns {Date} in timezone of browser
 */
export function setLocalZone(date, timezoneName) {
    const dateWithoutZone = moment
        .tz(date, timezoneName)
        .format('YYYY-MM-DDTHH:mm:ss.SSS');
    const localZone = moment(dateWithoutZone).format('Z');
    const dateWithLocalZone = [dateWithoutZone, localZone].join('');

    return new Date(dateWithLocalZone);
}

/**
 * Get the timezone object of the browser by list of timezones
 * @param {Array} timezones
 * @returns {Timezone} of browser or first timezone if not found
 */
export function guessTimezoneOfBrowser(timezones) {
    const browserTimezone = moment.tz.guess();
    const findTimezoneBrowser = (timezone) => {
        if (timezone && timezone.name) {
            let province = timezone.name.split('/');
            if (province.length === 0) return false;
            province = province[province.length - 1];
            return browserTimezone.includes(province);
        } else {
            return false;
        }
    };
    const browserTimezoneObject = timezones.find(findTimezoneBrowser);
    return browserTimezoneObject || timezones[0];
}

/**
 * Generates a random hexadecimal color
 * @param quantity - the number of random hex colors you want to generate
 * @returns An array of random hex colors
 */
export function getRandomHex(quantity) {
    function generarLetra() {
        var letras = [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
        ];
        var numero = (Math.random() * 15).toFixed(0);
        return letras[numero];
    }

    function colorHEX() {
        let color = '';

        for (let i = 0; i < 6; i++) {
            color = color + generarLetra();
        }
        return '#' + color;
    }

    const arr = [];

    for (let step = 0; step < quantity; step++) {
        const newRandomColor = colorHEX();
        arr.push(newRandomColor);
    }

    return arr;
}

/**
 * Converts an hexadecimal color code and to an rgb color code
 * @param color - The color you want to convert.
 * @returns - The rgb color code.
 */
export function hexaToRgb(color) {
    if (typeof color === 'object') {
        return color.map((col) => {
            const newStr = col.slice(1, 7);
            var aRgbHex = newStr.match(/.{1,2}/g);
            var aRgb = `rgb(${parseInt(aRgbHex[0], 16)},${parseInt(
                aRgbHex[1],
                16
            )},${parseInt(aRgbHex[2], 16)})`;
            return aRgb;
        });
    }
    const newStr = color.slice(1, 7);
    var aRgbHex = newStr.match(/.{1,2}/g);
    var aRgb = `rgb(${parseInt(aRgbHex[0], 16)},${parseInt(
        aRgbHex[1],
        16
    )},${parseInt(aRgbHex[2], 16)})`;
    return aRgb;
}

/**
 * @returns {Boolean} true if the platform is in maintenance
 */
export async function isInMaintenance() {
    let from = window.Config.REACT_APP_MAINTENANCE_FROM;
    let to = window.Config.REACT_APP_MAINTENANCE_TO;
    if (
        (from === null || from === undefined || from === '') &&
        (to === null || to === undefined || to === '')
    ) {
        return false;
    }
    from = moment.tz(from, 'Etc/UTC');
    to = moment.tz(to, 'Etc/UTC');
    let currentTime = await fetch(
        'https://worldtimeapi.org/api/timezone/Etc/UTC'
    )
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data.datetime;
        });
    currentTime = moment.tz(currentTime, 'Etc/UTC');
    return currentTime.isBetween(from, to);
}

/**
 * Get the status of one campaign
 * example:
 * campaignStatus("DELETED") => 4
 * campaignStatus(6) => "INACTIVE"
 * @param {Number or String} status
 * @returns {String or Number}
 */
export function campaignStatus(status) {
    const statuses = {
        DRAFT: 0,
        JOB_PENDING: 1,
        JOB_REJECTED: 2,
        JOB_SUCCESS: 3,
        DELETED: 4,
        ACTIVE: 5,
        INACTIVE: 6,
    };

    if (typeof status === 'string') {
        return statuses[status];
    } else {
        return Object.keys(statuses).find((statusString) => {
            return statuses[statusString] === status;
        });
    }
}

/**
 * Give a number a specific language-sensitive format.
 *
 * Converts a number to a string in specific language-sensitive format.
 * Currently supports the following formats:
 * - properCurrency: format the number to a proper currency format with monetary symbol
 * - currency: format the number to a currency formats
 * - rounded: format the number to a rounded formats
 * - short: format the number to a short formats (e.g. 1.2M)
 * - nomarker: remove dots and commas from the number (ie. 1,234,567.89 => 123456789)
 * - withcommas: retro compatability with the old format function numberWithCommas
 * - cpi: shows the first 2 decimals after de 0 if the number is < 1
 * - ecpm: remove decimals if number > 1
 * - impacts: If number == '0.00' then number = '0'
 *
 * @param {Number} number The number to formats
 * @param {String} format Type of format to be used
 * @param {String} localeOverride Override the navigator language
 *
 * @return {String} The formatted number
 **/
export const formatNumber = (number, format, localeOverride, options) => {
    let toFormat = parseFloat(number);

    if (options?.dashIfZero && toFormat === 0) {
        return '-';
    }
    // Clean up commas
    if (number && typeof number === 'string') {
        toFormat = number.replace(/,/g, '');
    }

    if (format !== 'withcommas' && isNaN(toFormat)) {
        return '-';
    }

    let locale = navigator.languages
        ? navigator.languages[0]
        : navigator.language;
    if (localeOverride) {
        locale = localeOverride;
    }

    let formatedNumber;

    // NOTE: If options == 'dashIfZero' and number == 0 or == 0.00 returns '-'

    switch (format) {
        case 'properCurrency':
            // This selects comma or dot as the decimal separator
            switch (options.currency) {
                case 'ARS':
                case 'MXN':
                case 'BRL':
                case 'EUR':
                case 'CLP':
                case 'SOL':
                    locale = 'es-AR';
                    break;
                default:
                    locale = 'en-US';
                    break;
            }
            formatedNumber = new Intl.NumberFormat(locale, {
                style: 'currency',
                currencyDisplay: 'narrowSymbol',
                currency: options.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                .format(toFormat)
                .toString()
                .replace(/\xa0/g, '');
            break;

        case 'currency':
            formatedNumber = new Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: options?.decimals
                    ? options?.decimals
                    : 2,
            }).format(toFormat);
            break;
        case 'rounded':
        case 'impacts':
            formatedNumber = new Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 0,
            }).format(Math.round(toFormat));
            break;
        case 'short':
            formatedNumber = new Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
                notation: 'compact',
            }).format(toFormat);
            // In some languages, compact notation adds a non-breakable character after the number
            // SEE: https://stackoverflow.com/questions/54242039
            formatedNumber = formatedNumber.toString().replace(/\xa0/g, '');
            break;
        case 'nomarker':
            // NOTE: This removes commas and dots from the number. Maybe we can
            // detect the decimal separator and remove only the dots or only the commas.
            formatedNumber = toFormat
                .toString()
                .replace(/\./g, '')
                .replace(/,/g, '');
            break;
        case 'withcommas':
            // HACK: This is a hack to get retro compat with the number with commas
            formatedNumber = numberWithCommas(number);
            break;
        case 'cpi':
            if (toFormat < 1) {
                formatedNumber = new Intl.NumberFormat(locale, {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumSignificantDigits: 2,
                })
                    .format(toFormat)
                    .toString()
                    .replace(/\xa0/g, '');
            } else {
                formatedNumber = formatNumber(toFormat, 'rounded');
            }
            break;
        case 'ecpm':
            if (toFormat > 1) {
                let truncated = Math.trunc(toFormat);

                formatedNumber = new Intl.NumberFormat(locale, {
                    style: 'decimal',
                    maximumFractionDigits: 1,
                })
                    .format(truncated)
                    .toString()
                    .replace(/\xa0/g, '');
            } else if (toFormat > 0 && toFormat < 1) {
                let fixedNumber = Number(toFormat).toFixed(2);
                formatedNumber = fixedNumber;
            }
            break;
        default:
            formatedNumber = toFormat.toString();
    }

    return formatedNumber;
};

/**
 * Get the margin from a price.
 * @param {Object} deals The deals object
 * @param {Number} margin The margin of the user to adjust
 * @returns {Number} The margin of the user null if no price or currency is given
 * @example
 * const cpm = cpmFromPrice(deals, margin);
 */
export const cpmFromPrice = (deals, margin) => {
    if (deals && deals.prices) {
        if (
            Array.isArray(deals.prices) &&
            deals.prices.length > 0 &&
            margin !== 1
        ) {
            if (deals.prices[0].hasOwnProperty('price')) {
                return deals.prices[0].price / (1 - margin);
            }
        }
    }
    return null;
};

/**
 * Convert a string to a floating point number.
 *
 * Try to converts number string to a floating point number for mathematical
 * operations.
 *
 * @param {Number} number The string to be converted
 *
 * @returns {Number} The converted number
 **/
export const toFloat = (number) => {
    if (number === null || number === undefined) return 0;
    let lastComma = number.toString().lastIndexOf(',');
    let lastDot = number.toString().lastIndexOf('.');
    if (lastDot > lastComma) {
        return parseFloat(number.toString().replace(/,/g, ''));
    } else {
        if (lastDot === -1) {
            return parseFloat(number.toString().replace(',', ''));
        } else {
            return parseFloat(
                number.toString().replace(/\./g, '').replace(',', '.')
            );
        }
    }
};

/**
 * It takes in an input and a type, and returns true if the input is valid for the type, and false if
 * it is not
 * @param input - the input value
 * @param type - the type of input you want to validate
 * @returns A boolean value
 */
export const isValidInput = (input, type) => {
    let isValid;
    if (input) {
        switch (type) {
            case 'email':
                let emailRegex =
                    /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                isValid = emailRegex.test(input);
                break;
            case 'phone':
                let numberPhoneRegex =
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                isValid = numberPhoneRegex.test(input);
                break;
            case 'number':
                isValid = !isNaN(parseFloat(input));
                break;
            case 'alphanumeric':
                let alphanumericRegex = /^[a-zA-Z0-9]+$/;
                isValid = alphanumericRegex.test(input);
                break;
        }
        return isValid;
    } else {
        return false;
    }
};

/**
 * Parse data from axios response.
 *
 * Extract data from axios response and return it.
 *
 * @param {Object} response The axios response
 *
 * @returns {Object} The parsed data or empty array if no data
 **/
export const getData = (response) => {
    return 'data' in response && 'data' in response.data
        ? response.data.data
        : [];
};

/**
 * Convert string date to iso8601 integer.
 *
 * Convert string date to iso8601 integer.
 *
 * @param {String} date The date to be converted
 *
 * @returns {Number} The iso8601 date integer or -1 if string is not a date
 */
export const dateToInt = (date) => {
    let dateObj = new Date(date);
    if (!date || isNaN(dateObj.getTime())) {
        return -1;
    }
    return parseInt(
        `${dateObj.getFullYear()}${(dateObj.getMonth() + 1)
            .toString()
            .padStart(2, '0')}${dateObj.getDate().toString().padStart(2, '0')}`
    );
};

/**
 * It takes an array the objets of screens and returns the maximum CPM value plus one
 * @param screens - The array the objets of screens that we're going to be using to calculate the max CPM.
 * @returns The maximum CPM value from the array of screens.
 */
export const getCpmMax = (screens) => {
    if (!screens) {
        return 0;
    }
    let arrayCpm = [];

    screens.forEach((screen) => {
        let cpmScreen =
            getValidatedPriceFromScreen(screen) &&
            parseFloat(screen?.deals?.prices[0].price);
        if (cpmScreen && cpmScreen > 0) {
            arrayCpm.push(cpmScreen);
        }
    });

    // sometimes the array is empty, so we need to check if it is empty
    if (arrayCpm.length === 0) {
        return 0;
    }
    let maxCpm = Math.max(...arrayCpm) + 1;
    maxCpm = isFinite(maxCpm) ? maxCpm : 0;
    return maxCpm;
};

/**
 * It takes a date string, a format string, and a boolean, and returns a formatted date string
 * @param date - The date you want to format.
 * @param [formatFrom=YYYY-MM-DD HH:mm:ss] - The format of the date you're passing in.
 * @param [isWithoutYear=true] - if true, the year will be removed from the date.
 * @returns {String} The formatted date string or "-" string if date is not a valid date
 */
export const dateByLanguage = (
    date,
    formatFrom = 'YYYY-MM-DD HH:mm:ss',
    isWithoutYear = false
) => {
    moment.locale(window.navigator.language || 'en-US');
    if (date?.length !== formatFrom?.length || !date) {
        return ' - ';
    }
    let dateResponse = moment(date, formatFrom).format('L');
    if (isWithoutYear) {
        return dateResponse.substring(0, 5);
    }

    return new Intl.DateTimeFormat(window.navigator.language).format(
        new Date(dateResponse)
    );
};

/**
 * It takes a date object, and returns a string in the format YYMMDDHH
 * @param [date] - The date to format. Defaults to the current date.
 */
export function formatDate(date = new Date()) {
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    const year = date.getFullYear().toString();
    const dateNew = ('0' + date.getDate()).slice(-2);

    return [
        year.slice(2, 4),
        padTo2Digits(date.getMonth() + 1),
        dateNew,
        padTo2Digits(date.getHours()),
    ].join('');
}

/**
 * It takes a currency code as an argument and returns the currency symbol for that currency code
 * @param field - The currency code you want to convert to a symbol.
 * @returns The currency symbol for the currency code passed in.
 */
export const getProperCurrency = (field) => {
    let locale = navigator.languages
        ? navigator.languages[0]
        : navigator.language;

    if (field) {
        return (0)
            .toLocaleString(locale, {
                style: 'currency',
                currencyDisplay: 'narrowSymbol',
                currency: field,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
            .replace(/\d/g, '')
            .trim();
    } else {
        return;
    }
};

/** Determine the data provider name by location
 *  @param countries - An array of objects with a value key with ISO Code2 of the country
 *  @returns The name of the provider if the country has provider, empty string
 *  otherwhise
 */
export function determineDataProvider(countries) {
    if (
        countries.some((country) => {
            return country.value === 'AR';
        })
    ) {
        return 'scopesi';
    }
    if (
        countries.some((country) => {
            return country.value === 'BR';
        })
    ) {
        return 'cinnecta';
    }
    if (
        countries.some((country) => {
            return country.value === 'ZA';
        })
    ) {
        return 'onemata';
    }
    return '';
}

/**
 * Converts color rgb to hexadecimal
 * @param r - The red value of the color (0-255)
 * @param g - The green value of the color.
 * @param b - The blue value of the color
 * @returns A hexadecimal number.
 */
export function rgbToHex(r, g, b) {
    return ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

export function getEmptyResponseDensity() {
    const days = ['sat', 'mon', 'fri', 'tue', 'sun', 'thu', 'wed'];
    let response = {};
    days.forEach((day) => {
        response[day] = [];
        for (let i = 0; i <= 23; i++) {
            response[day].push({ hour: i, impacts: 0, density: 1 });
        }
    });

    return response;
}

/**
 * It returns the provider name based on the countries selected
 * @param [shortNameCountries] - an array of countries in short name format (e.g. "US")
 * @returns The provider name
 */
export function getProviderByCountries(shortNameCountries = []) {
    for (let index = 0; index < COUNTRIES_WITH_PROVIDER.length; index++) {
        if (
            COUNTRIES_WITH_PROVIDER[index].countries.some((country) => {
                return shortNameCountries.some((countryParam) => {
                    return countryParam.value === country;
                });
            })
        ) {
            return COUNTRIES_WITH_PROVIDER[index].provider;
        }
    }
    return 'scopesi';
}

export function isAMPMlocale() {
    const now = new Date();
    const timestring = new Intl.DateTimeFormat(navigator.languages[0], {
        dateStyle: 'full',
        timeStyle: 'long',
    }).format(now);
    let pattern = /\b(?:AM|PM)\b/;
    return pattern.test(timestring);
}

export function setDefaultHourFormat() {
    const fallbackValue = isAMPMlocale() ? 'ampm' : '24hs';

    localStorage.getItem('hourFormat') ??
        localStorage.setItem('hourFormat', fallbackValue);
}

/**
 * Convert time_days to backend compatible format.
 * @param {Array} daysEnabled - The days selected
 * @returns {Array} The days selected in backend format
 * @example
 * // returns [1,2,3,4,5,6,7]
 * translateDaysEnabled([0,1,2,3,4,5,6])
 */
export function translateDaysEnabled(daysEnabled) {
    if (!daysEnabled) return daysEnabled;
    let translated = [...daysEnabled];

    if (translated.length > 7) throw new Error('Invalid daysEnabled');

    let allIndex = translated?.indexOf(7);
    if (allIndex > -1) {
        return [1, 2, 3, 4, 5, 6, 7];
    }

    let sundayIndex = translated?.indexOf(0);
    if (sundayIndex > -1) {
        translated[sundayIndex] = 7;
    }

    return translated.sort();
}

/**
 * Get the price from  screen.deals.prices
 * @param {Object} screen The deals object
 * @returns {Number} The margin of the user null if no price or currency is given
 * @returns {null} If the property does not exist returns null
 * @example
 * getValidatedPriceFromScreen(screen)
 */
export function getValidatedPriceFromScreen(screen) {
    if (
        screen &&
        screen.deals &&
        screen.deals.prices &&
        screen.deals.prices.length > 0 &&
        typeof screen.deals.prices[0].price !== 'undefined'
    ) {
        return screen.deals.prices[0].price;
    } else {
        return null;
    }
}

/**
 * Get the total sum of CPM by spots from selected screens.
 * @param {Array} screens - The array of selected screens.
 * @param {number} userMargin - A value less than 1, like 0.5, 0.7, or 0.25, used to obtain the user's margin.
 * @returns {number} The total sum of CPM from selected screens. Returns 0 if no screens are provided.
 * @example
 * const screens = [{ deals: { prices: [{ price: 10 }] } }, { deals: { prices: [{ price: 15 }] } }];
 * const userMargin = 0.5;
 * const totalCpm = getCpmSumFromSelectedScreens(screens, userMargin); // Assumes cpmFromPrice calculates CPM based on userMargin
 * // Returns the sum of CPM by spots considering userMargin
 */
export const getCpmSumFromSelectedScreens = (screens, userMargin) => {
    if (!screens || !screens.length) {
        return 0;
    }

    let totalCpm = 0;

    screens.forEach((screen) => {
        let priceBySpotCpm = cpmFromPrice(screen?.deals, userMargin) || 0;
        totalCpm += priceBySpotCpm;
    });
    return totalCpm;
};

/**
 * Injects CPM data onto screens based on screen ID matching.
 * @param {Array} screens - The array of screens to inject CPM data onto.
 * @param {Array} screensWithCpm - The array of screens with CPM data.
 * @returns {Array} An array of screens with injected CPM data.
 * @example
 * const screens = [{ id: 1 }, { id: 2 }, { id: 3 }];
 * const screensWithCpm = [{ screen_id: 1, median: 10, hasAudience: true, weeklyImpacts: 100, weeklyRequests: 50 }];
 * const screensWithInjectedCpm = injectCpmOnScreens(screens, screensWithCpm);
 * // Output: [{ id: 1, ecpm: 10, hasAudience: true, weeklyImpacts: 100, weeklyRequests: 50 }, { id: 2 }, { id: 3 }]
 */
export const injectCpmOnScreens = (screens, screensWithCpm) => {
    if (!screens?.length || !screensWithCpm?.length) {
        return;
    }
    return screens.map((screen) => {
        const ecpm = screensWithCpm.find((cpmScreen) => {
            return cpmScreen.screen_id === screen.id;
        });

        return {
            ...screen,
            ecpm: ecpm?.median,
            hasAudience: ecpm?.hasAudience,
            weeklyImpacts: ecpm?.weeklyImpacts,
            weeklyRequests: ecpm?.weeklyRequests,
        };
    });
};
