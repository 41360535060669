import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions, notificationsActions } from '../../../../../store';
import AsyncSelect from 'react-select/async';
import { getRandomHex, hexaToRgb } from '../../../../../utils/functions';
const colorsArr = hexaToRgb(getRandomHex(15));
let debounceLoadOptions;

const InputZoneSearch = (props) => {
    const { radius, id } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useSelector((state) => state.newCampaign.location);
    const random = Math.floor(Math.random() * colorsArr.length);
    const randomRgb = colorsArr[random];

    const notExists = (search) => {
        return !location.zones.some((zone) => zone.search === search);
    };

    const onSelectPredicition = async (prediction) => {
        /*global google*/
        /*eslint no-undef: "error"*/
        const service = new google.maps.places.PlacesService(
            document.createElement('div')
        );
        const search = prediction.description;
        if (notExists(search)) {
            const request = {
                query: search,
            };
            let rad = 400;
            switch (radius) {
                case 0:
                    rad = 400;
                    break;
                case 1:
                    rad = 600;
                    break;
                case 2:
                    rad = 800;
                    break;
                default:
                    rad = 400;
                    break;
            }
            const zones = [];
            service.textSearch(request, (results, status) => {
                if (status === 'OK') {
                    const zone = {
                        active: true,
                        search,
                        manual: false,
                        color: randomRgb,
                        results: [],
                        label: search,
                        colors: colorsArr,
                    };
                    for (let r of results) {
                        const res = {
                            ref: r.reference,
                            lat: r.geometry.location.lat(),
                            lng: r.geometry.location.lng(),
                            radius: rad,
                            color: zone.color,
                        };
                        zone.results.push(res);
                    }
                    zones.push(zone);
                    const newLocation = Object.assign({}, location);
                    newLocation.zones = location.zones.concat(zones);
                    dispatch(newCampaignActions.setLocation(newLocation));
                    dispatch(newCampaignActions.setMapLocations(newLocation));
                } else {
                    dispatch(
                        notificationsActions.setNotification({
                            type: 'error',
                            title: 'Error',
                            message: `Statuses Error: ${status}`,
                        })
                    );
                }
            });
        }
    };

    const loadOptions = (inputValue, callback) => {
        if (inputValue === '') {
            callback([]);
        } else {
            clearTimeout(debounceLoadOptions);
            debounceLoadOptions = setTimeout(() => {
                const service = new google.maps.places.AutocompleteService();
                service.getQueryPredictions({ input: inputValue }, (res) => {
                    if (res) {
                        const predictions = res.map((prediction) => ({
                            ...prediction,
                            label: prediction.description,
                            value: prediction.description,
                        }));
                        callback(predictions);
                    } else {
                        callback([]);
                    }
                });
            }, 300);
        }
    };

    return (
        <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={onSelectPredicition}
            placeholder={t(
                'sections.campaigns.new-campaign.wizard-basics.location.nearPlace-placeholder'
            )}
            noOptionsMessage={() =>
                t(
                    'sections.campaigns.new-campaign.wizard-basics.location.nearPlace-placeholder'
                )
            }
            value={null}
            inputId={id}
        />
    );
};

export default InputZoneSearch;
