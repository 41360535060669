const isProviderAvailableInCountriesSelected = (
    providerCountries,
    selectedCountries
) => {
    return providerCountries.some((country) => {
        return selectedCountries.some((selectedCountry) => {
            return selectedCountry.value === country;
        });
    });
};

const audienceProviders = [
    {
        title: 'Onemata',
        name: 'onemata',
        availableCountries: ['ZA', 'CL', 'MX', 'CO', 'AR', 'CR', 'PA', 'SV', 'GT', 'HN', 'EC'],
    },
    {
        title: 'Scopesi',
        name: 'scopesi',
        availableCountries: ['AR', 'PY'],
    },
    {
        title: 'Cinnecta',
        name: 'cinnecta',
        availableCountries: ['BR'],
    },
];

export { isProviderAvailableInCountriesSelected, audienceProviders };
