import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyText from '../../../CopyText/CopyText';
import { useDispatch, useSelector } from 'react-redux';
import { screensActions } from '../../../../store';

const ScreensList = ({ screens, title, icon, description, color }) => {
    const dispatch = useDispatch();
    const [isExpanded, setExpanded] = useState(false);
    const { selectedScreensLocal } = useSelector((state) => state.screens);

    /**logic for marrk an screen on the map
    (the map is gonna mark the fist screen in the array of selectedScreens)
    **/
    const setOnMap = (screenId) => {
        let allScreens = [];
        let firstScreen = null;
        for (const selected of selectedScreensLocal) {
            if (selected.id == screenId) {
                firstScreen = selected;
            } else {
                allScreens.push(selected);
            }
        }
        if (firstScreen) {
            allScreens = [firstScreen, ...allScreens];
        }
        dispatch(screensActions.setSelectedScreensLocal(allScreens));
    };

    const toggleExpansion = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div className="flex-column">
            <div
                className="flex justify-around w-full  w-full cursor-pointer"
                onClick={toggleExpansion}
            >
                <div className="flex flex-col w-full">
                    <span className="font-bold">
                        {icon?.type && icon?.name && (
                            <FontAwesomeIcon
                                icon={[icon.type, icon.name]}
                                className={`mr-1 text-${color}`}
                            />
                        )}
                        {title}
                    </span>
                    {description && (
                        <span className="font-light mb-4">{description}</span>
                    )}
                </div>
                <button className="text-gray-500 focus:outline-none">
                    {isExpanded ? (
                        <FontAwesomeIcon
                            icon={['fal', 'angle-down']}
                            className={`mr-1 text-blue-400`}
                            style={{ fontSize: '24px' }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={['fal', 'angle-left']}
                            className={`mr-1 text-blue-400`}
                            style={{ fontSize: '24px', marginLeft: '2px' }}
                        />
                    )}
                </button>
            </div>
            {isExpanded && (
                <div>
                    <ul className="list-none">
                        {screens.length
                            ? screens.map((screen) => (
                                  <li
                                      className="flex flex-row space-x-2" // Modificado aquí
                                      key={screen.id}
                                  >
                                      <div className="basis-1/6 text-xs text-gray-600 self-baseline">
                                          <CopyText
                                              text={screen.id.toUpperCase()}
                                          />
                                      </div>
                                      <div className="basis-5/6 text-ellipsis self-baseline">
                                          {/* {<CopyText text={screen.name} />} */}
                                          <button
                                              onClick={() =>
                                                  setOnMap(screen.id)
                                              }
                                          >
                                              {screen.name}
                                          </button>
                                      </div>
                                  </li>
                              ))
                            : 'nothing to show'}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ScreensList;
