import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';
import ModalHeader from '../Modal/ModalHeader/ModalHeader';
import ModalBody from '../Modal/ModalBody/ModalBody';
import ModalFooter from '../Modal/ModalFooter/ModalFooter';
import CommonButton from './CommonButton';

function CommonDropdown(params) {
    const icon = params.icon;
    const list = params.list;
    const isDeleted = params.isDeleted || false;
    const [isOpen, setIsOpen] = useState(false);
    const [showModalWarning, setShowModalWarning] = useState(false);
    const toggleModalWarning = () => setShowModalWarning(!showModalWarning);
    const { t } = useTranslation();
    const handleDelet = () => {
        setIsOpen(false);
        toggleModalWarning();
        // list[3] is function delet
        if (typeof list[3].function === 'function') list[3].function();
    };
    const handleCancel = () => {
        toggleModalWarning();
    };

    if (isOpen === true) {
        setTimeout(() => {
            setIsOpen(false);
        }, 5000);
    }

    const handleClickLabel = (item) => {
        if (item.icon == 'trash') {
            toggleModalWarning();
            return;
        }
        setIsOpen(false);
        if (typeof item.function === 'function') item.function();
    };

    return (
        <>
            <div className="relative mt-0.5 ml-1 cursor-pointer">
                {!isDeleted && (
                    <Modal
                        size="sm"
                        active={showModalWarning}
                        toggler={toggleModalWarning}>
                        <ModalHeader toggler={toggleModalWarning}>
                            {list[3].label + ' ' + list[0].id}
                        </ModalHeader>
                        <ModalBody>
                            <p className="text-base leading-relaxed text-gray-600 font-normal">
                                {t('sections.campaign-list.warning')}
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <CommonButton
                                onClick={handleCancel}
                                label={t('common.buttons.cancel')}
                                styleType={'primary'}
                                className="mx-2 capitalize "
                            />
                            <CommonButton
                                onClick={handleDelet}
                                label={t('common.words.delete')}
                                styleType={'danger'}
                                className="ml-2 capitalize "
                            />
                        </ModalFooter>
                    </Modal>
                )}
                <FontAwesomeIcon
                    onClick={() => setIsOpen(!isOpen)}
                    icon={['fas', icon]}
                    className="text-gray-400"
                />
                <div
                    className={`${
                        isOpen ? 'block' : 'hidden'
                    } absolute z-30 right-2 bg-white rounded-lg shadow-md hover:shadow-xl p-2 text-gray-500 text-xs`}>
                    <ul>
                        {list.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={() => handleClickLabel(item)}
                                    className={`${
                                        item.style !== null ? item.style : ''
                                    } hover:bg-gray-100 hover:text-black transition-colors duration-100 p-2 rounded w-36`}>
                                    <FontAwesomeIcon
                                        icon={['fad', item.icon]}
                                        fixedWidth
                                        className="mr-1"
                                    />
                                    {item.action === 'link' ? (
                                        <NavLink to={item.path + item.id}>
                                            {item.label}
                                        </NavLink>
                                    ) : (
                                        <span>{item.label}</span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default CommonDropdown;
